import classNames from "classnames";
import Portal from "components/core/Portal/Portal";
import React, { useState } from "react";
import styles from "./GlobalNotification.module.scss";
import { CloseOutlined } from "@ant-design/icons";

type Props = {
  children: React.ReactNode;
  onClose: () => void;
};

const GlobalNotification = (props: Props) => {
  const [closing, setClosing] = useState<boolean>(false);
  const [close, setClose] = useState<boolean>(false);

  const closeNotification = () => {
    setClosing(true);
    setTimeout(function () {
      setClosing(false);
      setClose(true);
      props.onClose();
    }, 290);
  };

  if (close) {
    return <></>;
  }

  return (
    <Portal containerSelector="#notification-container">
      <div className={styles.parent}>
        <div
          className={classNames(styles.notification, closing && styles.closing)}
        >
          <div className={styles.closeContainer}>
            <div className={styles.closeButtonContainer}>
              <CloseOutlined
                className={styles.closeButton}
                onClick={closeNotification}
              />
            </div>
          </div>
          {props.children}
        </div>
      </div>
    </Portal>
  );
};

export default GlobalNotification;
