import { useRouter } from "next/router";
import { triggerGenericSymbolEvent } from "./analytics-events";
import { Entry } from "contentful";
import { Dictionary } from "lodash";

export const parseTextForTickerSymbol = (
  text: string
): (string | JSX.Element)[] => {
  const tickerRegEx = /(Tii:[\w-]{1,}\.\w{1,})|(Tii:[\w-]{1,})/gi;
  return text.split(tickerRegEx).reduce((children, textSegment) => {
    const tickerSymbol =
      textSegment && textSegment.match(tickerRegEx)
        ? textSegment.split(":")[1]
        : null;
    return [
      ...children,
      tickerSymbol ? (
        <a
          onClick={() => triggerGenericSymbolEvent(tickerSymbol)}
          key={tickerSymbol}
          href={`/brand/${tickerSymbol}`}
        >
          {textSegment}
        </a>
      ) : (
        textSegment
      ),
    ];
  }, []);
};

export type GAEvent = {
  category: string;
  action: string;
  label: string;
};
export const triggerGAEvent = (event: GAEvent): void => {
  if (typeof window !== "undefined" && window["gtag"]) {
    window["gtag"]("event", event.action, {
      event_category: event.category,
      event_label: event.label,
    });
  } else {
    console.debug("GA Not available");
  }
};

export const triggerBingAdsEvent = (event: GAEvent): void => {
  if (typeof window !== "undefined" && window["uetq"]) {
    window["uetq"].push("event", event.action, {
      event_label: event.label,
      event_category: event.category,
    });
  } else {
    console.debug("Bing Ads not available");
  }
};

export const triggerMetaEvent = (event: GAEvent): void => {
  if (typeof window !== "undefined" && window["fbq"]) {
    window["fbq"].push("track", event.action, {
      event_label: event.label,
      event_category: event.category,
    });
  } else {
    console.debug("Bing Ads not available");
  }
};

export function groupContentfulEntriesBy<T>(
  ungroupedEntries: Entry<T>[],
  key: string
): Dictionary<Entry<T>[]> {
  return ungroupedEntries.reduce((r, d: { fields: T }) => {
    (r[d.fields[key]] = r[d.fields[key]] || []).push(d);
    return r;
  }, {});
}

export const classNames = (
  classNames: (string | undefined | null | false)[]
): string => {
  return classNames.filter(Boolean).join(" ");
};
