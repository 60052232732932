import { Auth0Provider } from "@auth0/auth0-react";
import UserWrapper from "source/hoc/UserWrapper";
import React from "react";
import AuthenticatedApolloProvider from "./AuthenticatedApolloProvider";

interface Props {
  children: React.ReactNode;
}

const AuthWrapper = (props: Props) => {
  const opts = {
    clientId: process.env.FRONTEND_AUTH0_CLIENT_ID || "",
    domain: process.env.AUTH0_DOMAIN || "",
    audience: process.env.API_IDENTIFIER || "",
  };

  if (!opts.clientId || !opts.domain || !opts.audience) {
    console.log(opts);
    throw new Error("Not all Auth0 properties were provided");
  }

  return (
    <Auth0Provider
      scope="read:current_user update:current_user_metadata"
      {...opts}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <AuthenticatedApolloProvider>
        <UserWrapper>{props.children}</UserWrapper>
      </AuthenticatedApolloProvider>
    </Auth0Provider>
  );
};

export default AuthWrapper;
