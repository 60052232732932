import React, { useEffect } from "react";
import { ClientUser } from "source/hooks";
import {
  FetchResult,
  LazyQueryResult,
  QueryTuple,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import {
  CreateActivity,
  EntityType,
  ActivityType,
} from "$gql/mutations/general/CreateActivity.gen";
import { GetUserRecommendations } from "$gql/queries/general/GetUserRecommendations.gen";
import { useFetchUser } from "source/hooks";
import { useContext } from "react";
import { useSession } from "source/hooks";

interface RecommendationApi {
  addView(symbol: string, entityType: EntityType): void;
  addInteract(symbol: string, entityType: EntityType): void;

  getRecommendations(): void;
}

export interface BrandRecommendation {
  ticker: string;
  probability: number;
}

export class RecommendationHolder implements RecommendationApi {
  readonly user: ClientUser | null | undefined;
  readonly getRecommendationsTuple: QueryTuple<
    GetUserRecommendations.Query,
    GetUserRecommendations.Variables
  >;
  readonly interact: (options?: {
    variables: CreateActivity.Variables;
  }) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  readonly session: string;
  public data: LazyQueryResult<
    GetUserRecommendations.Query,
    GetUserRecommendations.Variables
  >;

  addRecommendClick = (symbol: string) => {
    const variables: CreateActivity.Variables = {
      title: symbol,
      entityType: EntityType.Company,
      activityType: ActivityType.RecommendClick,
      session: this.session,
    };

    this.interact({ variables });
  };

  addBrandWebsiteView = (symbol: string, entityType: EntityType) => {
    const variables: CreateActivity.Variables = {
      title: symbol,
      entityType: entityType,
      activityType: ActivityType.BrandWebsiteView,
      session: this.session,
    };

    this.interact({ variables });
  };

  addExternalArticleView = (symbol: string, entityType: EntityType) => {
    const variables: CreateActivity.Variables = {
      title: symbol,
      entityType: entityType,
      activityType: ActivityType.ExternalArticleView,
      session: this.session,
    };
    this.interact({ variables });
  };

  addView = (symbol: string, entityType: EntityType, entityId?: string) => {
    const variables: CreateActivity.Variables = {
      title: symbol,
      entityType: entityType,
      activityType: ActivityType.View,
      session: this.session,
      entityId,
    };

    this.interact({ variables });
  };

  addInteract = (symbol: string, entityType: EntityType) => {
    const variables: CreateActivity.Variables = {
      title: symbol,
      entityType: entityType,
      activityType: ActivityType.Interact,
      session: this.session,
    };

    this.interact({ variables });
  };

  addRedirect = (symbol: string, entityType: EntityType) => {
    const variables: CreateActivity.Variables = {
      title: symbol,
      entityType: entityType,
      activityType: ActivityType.Redirect,
      session: this.session,
    };

    this.interact({ variables });
  };

  getRecommendations = async (brandCount?: number) => {
    const [getRecs, data] = this.getRecommendationsTuple;
    getRecs({
      variables: {
        userId: this.user?.id,
        recommendationCount: brandCount,
      },
    });
  };

  useBrands = (brandCount?: number): BrandRecommendation[] => {
    useEffect(() => {
      this.getRecommendations(brandCount);
    }, [this.user?.id]);

    const symbols = this.data.data?.getRecommendationsForUser.brands || [];

    return symbols;
  };

  constructor(
    user: ClientUser | null | undefined,
    getRecommendations: QueryTuple<
      GetUserRecommendations.Query,
      GetUserRecommendations.Variables
    >,
    interact: (options?: {
      variables: CreateActivity.Variables;
    }) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>,
    sessionId: string
  ) {
    this.user = user;
    this.interact = interact;
    this.session = sessionId;
    this.getRecommendationsTuple = getRecommendations;

    const [getRecs, data] = getRecommendations;
    this.data = data;
  }
}

export const RecommendationContext = React.createContext<RecommendationHolder>(
  null!
);

export const useRecommendations = () => {
  return useContext(RecommendationContext);
};

export const RecommendationProvider: React.FC<{}> = (props) => {
  const { user } = useFetchUser();

  const getRecommendationsTuple = useLazyQuery<
    GetUserRecommendations.Query,
    GetUserRecommendations.Variables
  >(GetUserRecommendations.Document);
  const [createActivity] = useMutation(CreateActivity.Document);
  const session = useSession();

  return (
    <RecommendationContext.Provider
      value={
        new RecommendationHolder(
          user,
          getRecommendationsTuple,
          createActivity,
          session
        )
      }
    >
      {props.children}
    </RecommendationContext.Provider>
  );
};
