import React, { useRef, useEffect, useState } from "react";
import { GetPlaidSyncStatus } from "$gql/queries/general/GetPlaidSyncStatus.gen";
import { useQuery } from "@apollo/client";

interface Props {
  institutionLoginId: number;
}

export function useInterval(callback, delay, intervalCount = -1) {
  const [count, setCount] = useState(0);

  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (intervalCount != -1 && count >= intervalCount) {
        return;
      }

      // @ts-ignore
      savedCallback.current();
      setCount(count + 1);
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const PollPlaidStatus = (props: Props) => {
  const result = useQuery(GetPlaidSyncStatus.Document, {
    variables: {
      institutionLoginId: props.institutionLoginId,
    },
  });

  useInterval(() => {
    result.refetch({ institutionLoginId: props.institutionLoginId });
  }, 1000);

  return <></>;
};

export default PollPlaidStatus;
