import { useRouter } from "next/router";
import React from "react";
import styles from "./ImpersonationBanner.module.scss";
import Cookies from "js-cookie";

interface Props {}

const ImpersonationBanner = (props: Props) => {
  const router = useRouter();

  const endImpersonation = () => {
    Cookies.remove("impersonatedUser");
    router.reload();
  };

  return (
    <div className={styles.ImpersonationBanner}>
      You are impersonating - <a onClick={endImpersonation}>End</a>
    </div>
  );
};

export default ImpersonationBanner;
