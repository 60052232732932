// @ts-nocheck
import { GetUserHoldings } from "$gql/queries/general/GetUserHoldings.gen";
import React, { useEffect, useState, useContext } from "react";
import { CookieName, useMutationBundle, useQueryBundle } from "source/hooks";
import {
  EligibilityStatus,
  ErrorType,
  PlaidLoginStatus,
  Referral,
} from "$gql/types.gen";
import { UserLoginFragment } from "$gql/fragments/general/UserLogin.gen";
import { GetUserPerks } from "$gql/queries/general/GetUserPerks.gen";
import { FetchResult, useMutation } from "@apollo/client";
import {
  CreateInstitutionLoginForUser,
  CreateInstitutionLoginForUserMutation,
} from "$gql/mutations/general/CreateInstitutionLoginForUser.gen";
import {
  GeneratePlaidLinkToken,
  GeneratePlaidLinkTokenMutation,
} from "$gql/mutations/general/GeneratePlaidLinkToken.gen";
import createPlaid, { PlaidAPI } from "components/PlaidLink/factory";
import { fixBodyCss, PlaidErrorDetails } from "components/PlaidLink/plaidLink";
import {
  SubmitPlaidAccountConnectionErrorForm,
  SubmitPlaidAccountConnectionErrorFormMutation,
} from "$gql/mutations/general/SubmitPlaidAccountConnectionErrorForm.gen";
import PlaidErrorMessage from "components/PlaidLink/PlaidErrorMessage";
import { triggerGAEvent } from "source/ui-utils";
import { useInterval } from "components/PlaidLink/PollPlaidStatus";
import {
  FixLoginPlaidStatus,
  FixLoginPlaidStatusMutation,
} from "$gql/mutations/general/FixLoginPlaidStatus.gen";
import * as DateIso from "@tiicker/util/lib/date-iso";
import * as TimeDateIso from "@tiicker/util/lib/date-iso/time-date-iso";
import { compact, update } from "lodash";
import { triggerBrokerageConnectGAW } from "source/analytics-events";
import GlobalNotification from "components/GlobalNotification/GlobalNotification";
import Cookies from "js-cookie";
import { GetUsersPendingReferrals } from "$gql/queries/general/GetUsersPendingReferrals.gen";
import { UpdatePendingReferral } from "$gql/mutations/general/UpdatePendingReferral.gen";
import { GetReferralsAssociatedWithUserDocument } from "$gql/queries/general/GetReferralsAssociatedWithUser.gen";
import { UpdateUserAccountInformation } from "$gql/mutations/general/UpdateUserAccountInformation.gen";
import { UpdateUserAccountProxyInformation } from "$gql/mutations/general/UpdateUserAccountProxyInformation.gen";
import { UpdateUserAccountInstagramInformation } from "$gql/mutations/general/UpdateUserAccountInstagramInformation.gen";
import { GetAllReferralBrokerageLimits } from "$gql/queries/admin/GetAllReferralBrokerageLimits.gen";
import { UpdateUserFingerprint } from "$gql/mutations/general/UpdateUserFingerprint.gen";
import { useRouter } from "next/router";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { UpdateHoldingsForLogin } from "$gql/mutations/general/UpdateHoldingsForLogin.gen";
import { GetPlaidSyncStatus } from "$gql/queries/general/GetPlaidSyncStatus.gen";
import { useFlags } from "source/hooks/useFlags";

interface UserContextApi {
  refetchLoginsAndAccounts(): Promise<void>;
}

type UserState = {
  userId: number | undefined;
  availablePerks: {
    perkId: string;
    tickerSymbol: string;
  }[];
  claimedPerkIds: string[];
  claimedPerks: {
    claimedPerkId: number;
    perkId: string;
  }[];
  potentialFraudPerkIds: string[];
  logins: UserLoginFragment[];
  totalPortfolioValue: number;
  recurring: {
    perkId: string;
    recurringDate: DateIso.Type;
  }[];
  hasClaimedTiiCKERPerk: boolean;
  isEligibleForTiiCKERPerk: boolean;
  loading: boolean;
  plaidErrorDetails: PlaidErrorDetails | undefined;
};

export class UserHolder implements UserContextApi {
  readonly state: UserState;
  private setState: (state: Partial<UserState>) => void;
  private readonly generatePlaidLinkToken: (options?: {
    variables: GeneratePlaidLinkToken.Variables;
  }) => Promise<
    FetchResult<
      GeneratePlaidLinkTokenMutation,
      Record<string, any>,
      Record<string, any>
    >
  >;
  private readonly submitConnectionError: (options?: {
    variables: SubmitPlaidAccountConnectionErrorForm.Variables;
  }) => Promise<
    FetchResult<
      SubmitPlaidAccountConnectionErrorFormMutation,
      Record<string, any>,
      Record<string, any>
    >
  >;
  private readonly createInstitutionLogin: (options?: {
    variables: CreateInstitutionLoginForUser.Variables;
  }) => Promise<
    FetchResult<
      CreateInstitutionLoginForUserMutation,
      Record<string, any>,
      Record<string, any>
    >
  >;
  private readonly fixLoginPlaidStatus: (options?: {
    variables: FixLoginPlaidStatus.Variables;
  }) => Promise<
    FetchResult<
      FixLoginPlaidStatusMutation,
      Record<string, any>,
      Record<string, any>
    >
  >;
  private readonly updateLoginsAndAccounts: () => Promise<void>;

  // This is assigned in the constructor
  refetchLoginsAndAccounts = (): Promise<void> => {
    return this.updateLoginsAndAccounts();
  };

  constructor(
    state: UserState,
    setState: (state: Partial<UserState>) => void,
    refreshQueries: () => Promise<void>,

    generatePlaidLink: (options?: {
      variables: GeneratePlaidLinkToken.Variables;
    }) => Promise<
      FetchResult<
        GeneratePlaidLinkTokenMutation,
        Record<string, any>,
        Record<string, any>
      >
    >,
    submitConnectionError: (options?: {
      variables: SubmitPlaidAccountConnectionErrorForm.Variables;
    }) => Promise<
      FetchResult<
        SubmitPlaidAccountConnectionErrorFormMutation,
        Record<string, any>,
        Record<string, any>
      >
    >,
    createInstitutionLoginForUser: (options?: {
      variables: CreateInstitutionLoginForUser.Variables;
    }) => Promise<
      FetchResult<
        CreateInstitutionLoginForUserMutation,
        Record<string, any>,
        Record<string, any>
      >
    >,
    fixLoginPlaidStatus: (options?: {
      variables: FixLoginPlaidStatus.Variables;
    }) => Promise<
      FetchResult<
        FixLoginPlaidStatusMutation,
        Record<string, any>,
        Record<string, any>
      >
    >
  ) {
    this.state = state;
    this.setState = setState;
    this.updateLoginsAndAccounts = refreshQueries;
    this.generatePlaidLinkToken = generatePlaidLink;
    this.submitConnectionError = submitConnectionError;
    this.createInstitutionLogin = createInstitutionLoginForUser;
    this.fixLoginPlaidStatus = fixLoginPlaidStatus;
  }

  async openPlaid(institutionLoginId?: number, oauthToken?: string) {
    const linkTokenCookie = Cookies.get(CookieName.PlaidLinkToken);

    // Fetch link token here
    const linkToken = linkTokenCookie
      ? JSON.parse(linkTokenCookie)
      : (
          await this.generatePlaidLinkToken({
            variables: {
              institutionLoginId,
            },
          })
        ).data?.generatePlaidLinkToken;

    if (!linkTokenCookie) {
      // We are storing this as a cookie for Plaid oauth
      // we must use the same link token when they are redirected back to TiiCKER
      // we expire it in 30 minutes
      Cookies.set(CookieName.PlaidLinkToken, JSON.stringify(linkToken), {
        expires: 1 / 48,
      });
    }

    const plaidApi = await this.initalizePlaid(
      this.state.userId,
      linkToken,
      this.createInstitutionLogin,
      this.updateLoginsAndAccounts,
      this.fixLoginPlaidStatus,
      institutionLoginId,
      oauthToken
    );

    plaidApi.open();
  }

  // Passing in mutation and query because it is getting the state from the plaid link component
  async initalizePlaid(
    userId,
    linkToken,
    createLogin,
    refresh,
    fixStatus,
    institutionLoginId,
    oauthToken
  ) {
    const openErrorNotification = (errorData: PlaidErrorDetails) => {
      this.setState({
        plaidErrorDetails: errorData,
      });

      // const updateTitle = (title: string) => {
      //   notification.open({
      //     className: "connectionIssueModal",
      //     key,
      //     message: title,
      //     duration: 0,
      //     description: (
      //       <PlaidErrorMessage
      //         onClose={closeNotification}
      //         reportMessage={reportMessage}
      //         plaidErrorDetails={errorData}
      //         updateTitle={updateTitle}
      //       />
      //     ),
      //     placement: "topRight",
      //     onClose: () => {
      //       if (errorData.error) {
      //         reportMessage();
      //       }
      //       closeNotification();
      //     },
      //     style: {
      //       width: 500,
      //     },
      //   });
      // };

      // const closeNotification = () => {
      //   notification.close(key);
      // };

      // notification.open({
      //   className: "connectionIssueModal",
      //   key,
      //   message: "Hello!",
      //   duration: 0,
      //   description: (
      //     <PlaidErrorMessage
      //       onClose={closeNotification}
      //       reportMessage={reportMessage}
      //       plaidErrorDetails={errorData}
      //       updateTitle={updateTitle}
      //     />
      //   ),
      //   placement: "topRight",
      //   onClose: () => {
      //     if (errorData.error) {
      //       reportMessage();
      //     }
      //     // closeNotification();
      //   },
      //   style: {
      //     width: 500,
      //     maxWidth: "100%",
      //     marginLeft: 8,
      //   },
      // });
    };

    var handler = !linkToken
      ? {
          open: () => {},
          initialized: false,
        }
      : ({
          ...createPlaid({
            token: linkToken,
            receivedRedirectUri: oauthToken ? window.location.href : undefined,
            onLoad: () => {},
            onExit: (err, meta) => {
              const errorDataDetails: PlaidErrorDetails = {
                error: err
                  ? {
                      errorCode: err.error_code,
                      errorType: err.error_type,
                      errorMessage: err.error_message,
                    }
                  : undefined,
                metadata: meta
                  ? {
                      institutionName: meta.institution?.name,
                      institutionId: meta.institution?.institution_id,
                      linkSessionId: meta.link_session_id,
                      requestId: meta.request_id,
                      status: meta.status,
                      accountId: meta.account_id,
                    }
                  : undefined,
              };

              // There was potentially an error with the Plaid link token,
              // we want to re-generate it so we have a new one to play well with
              if (
                // To not get stuck in a loop we make sure there hasn't already been an error, if there has we do not regenerate the link token
                (!this.state.plaidErrorDetails ||
                  !this.state.plaidErrorDetails.error) &&
                err &&
                (err.error_code === "INVALID_LINK_TOKEN" ||
                  err.error_code === "INVALID_FIELD")
              ) {
                this.generatePlaidLinkToken({
                  variables: {
                    institutionLoginId,
                  },
                }).then((response) => {
                  if (response.data?.generatePlaidLinkToken) {
                    // We are storing this as a cookie for Plaid oauth
                    // we must use the same link token when they are redirected back to TiiCKER
                    // we expire it in 30 minutes
                    Cookies.set(
                      CookieName.PlaidLinkToken,
                      JSON.stringify(response.data?.generatePlaidLinkToken),
                      {
                        expires: 1 / 48,
                      }
                    );
                  }
                  this.openPlaid();
                  return;
                });
              } else {
                openErrorNotification(errorDataDetails);
              }

              fixBodyCss();
            },
            onEvent: (eventName, meta) => {},
            onSuccess: async function (publicToken) {
              fixBodyCss();

              // If we have a login id we fixed an account in a bad state
              // we just want to update the status then refresh the account
              if (institutionLoginId) {
                await fixStatus({
                  variables: {
                    institutionLoginId,
                  },
                });
                refresh();
                return;
              }

              const results = await createLogin({
                variables: {
                  data: {
                    publicToken,
                    userId: userId,
                  },
                },
              });

              // Handle Error Cases
              if (
                results.data?.createInstitutionLoginForUser?.__typename ===
                "GeneralError"
              ) {
                const error = results.data.createInstitutionLoginForUser;

                if (error.errorType === ErrorType.DuplicateAccount) {
                  // setDuplicateAccount(true);
                  return;
                }

                console.error("Create Error:", error);
                return;
              }

              if (
                results.data?.createInstitutionLoginForUser?.__typename ===
                "InstitutionLogin"
              ) {
                const loginId = results.data.createInstitutionLoginForUser.id;
                // props.genericCallbackHook && props.genericCallbackHook(loginId);
                // perkContext.addNewLoginId(loginId);

                // Trigger connected account action for user in Google Analytics
                triggerGAEvent({
                  action: "Brokerage",
                  category: "Connect",
                  label: "Plaid",
                });

                // Trigger connected account for Google AdWords
                triggerBrokerageConnectGAW();
              }

              refresh();
            },
          }),
          initialized: true,
        } as PlaidAPI);

    return handler;
  }
}

export const UserContext = React.createContext<UserHolder>(null!);

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider: React.FC<{}> = (props) => {
  const [state, setState] = useState<UserState>({
    availablePerks: [],
    claimedPerkIds: [],
    claimedPerks: [],
    potentialFraudPerkIds: [],
    logins: [],
    userId: undefined,
    totalPortfolioValue: 0,
    recurring: [],
    hasClaimedTiiCKERPerk: false,
    isEligibleForTiiCKERPerk: false,
    loading: true,
    plaidErrorDetails: undefined,
  });
  const [userPhoneNumber, setUserPhoneNumber] = useState<string | null>(null);
  const [userInstagramAccount, setUserInstagramAccount] = useState<
    string | null
  >(null);
  const [proxyBrand, setProxyBrand] = useState<string | null>(null);
  const { isLoading, error, data, getData } = useVisitorData(
    { extendedResult: true },
    { immediate: false }
  );
  const flags = useFlags();

  const [addInstitutionLogin] = useMutation<
    CreateInstitutionLoginForUser.Mutation,
    CreateInstitutionLoginForUser.Variables
  >(CreateInstitutionLoginForUser.Document);
  const [generatePlaidLinkToken] = useMutation<
    GeneratePlaidLinkToken.Mutation,
    GeneratePlaidLinkToken.Variables
  >(GeneratePlaidLinkToken.Document);
  const [submitPlaidConnectionError] = useMutation<
    SubmitPlaidAccountConnectionErrorForm.Mutation,
    SubmitPlaidAccountConnectionErrorForm.Variables
  >(SubmitPlaidAccountConnectionErrorForm.Document);
  const [fixLoginPlaidStatus] = useMutation<
    FixLoginPlaidStatus.Mutation,
    FixLoginPlaidStatus.Variables
  >(FixLoginPlaidStatus.Document);

  const [refreshHoldings] = useMutationBundle(UpdateHoldingsForLogin);
  const holdingsQuery = useQueryBundle(GetUserHoldings);
  const userPerks = useQueryBundle(GetUserPerks);
  const pendingReferralQuery = useQueryBundle(GetUsersPendingReferrals);
  const brokerageLimitHistory = useQueryBundle(GetAllReferralBrokerageLimits);
  const [updatePendingReferral] = useMutationBundle(UpdatePendingReferral, {
    refetchQueries: [{ query: GetReferralsAssociatedWithUserDocument }],
  });
  const [updateUserAccountInfo] = useMutationBundle(
    UpdateUserAccountInformation
  );
  const [updateUserAccountProxyInformation] = useMutationBundle(
    UpdateUserAccountProxyInformation
  );
  const [updateUserAccountInstagramInformation] = useMutationBundle(
    UpdateUserAccountInstagramInformation
  );
  const [updateUserFingerprint] = useMutationBundle(UpdateUserFingerprint);
  const router = useRouter();

  useEffect(() => {
    // Check for maintenance mode here
    if (flags && flags.webDownForMaintenance) {
      router.push("/maintenance");
    }
  }, [flags, router]);

  useEffect(() => {
    const localBrand = localStorage.getItem("brand");
    const localPhoneNumber = localStorage.getItem("phoneNumber");
    const localInstagramAccount = localStorage.getItem("InstagramHandle");
    if (localBrand) {
      setProxyBrand(localBrand);
    }
    if (localPhoneNumber) {
      setUserPhoneNumber(localPhoneNumber);
    }
    if (localInstagramAccount) {
      setUserInstagramAccount(localInstagramAccount);
    }
  }, []);

  useEffect(() => {
    if (state.userId) {
      getData({ ignoreCache: true, extendedResult: true }).then((result) => {
        updateUserFingerprint({
          variables: {
            userId: state.userId,
            visitorId: result.visitorId,
            requestId: result.requestId,
            deviceType: "web",
          },
        });
      });
    }
  }, [state.userId]);

  useEffect(() => {
    const fetchData = async () => {
      if (state.userId && proxyBrand) {
        const phoneNumber = localStorage.getItem("phoneNumber");
        const signup = !!phoneNumber;
        const results = await updateUserAccountProxyInformation({
          variables: {
            id: state.userId,
            signup,
            brand: proxyBrand,
          },
        });
        if (results.data) {
          localStorage.removeItem("brand");
        }
      }
    };

    fetchData();
  }, [proxyBrand, state.userId]);

  useEffect(() => {
    const fetchData = async () => {
      if (state.userId && userPhoneNumber) {
        const result = await updateUserAccountInfo({
          variables: {
            id: state.userId,
            data: { phoneNumber: userPhoneNumber },
          },
        });

        if (result.data) {
          localStorage.removeItem("phoneNumber");
        }
      }
    };

    fetchData();
  }, [userPhoneNumber, state.userId]);

  useEffect(() => {
    const fetchData = async () => {
      if (state.userId && userInstagramAccount) {
        const result = await updateUserAccountInstagramInformation({
          variables: {
            id: state.userId,
            instagram: userInstagramAccount,
          },
        });

        if (result.data) {
          localStorage.removeItem("InstagramHandle");
          localStorage.setItem("InstagramUser", userInstagramAccount);
        }
      }
    };

    fetchData();
  }, [userInstagramAccount, state.userId]);

  useEffect(() => {
    if (userPerks.state === "DONE") {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        availablePerks: userPerks.data.getUserPerks
          .filter((perk) => perk.eligible === EligibilityStatus.Eligible)
          .map((x) => {
            return {
              perkId: x.perkId,
              tickerSymbol: x.tickerSymbol,
            };
          }),
        claimedPerkIds: userPerks.data.getUserPerks
          .filter((perk) => perk.eligible === EligibilityStatus.Alreadyclaimed)
          .map((x) => x.perkId),
        claimedPerks: userPerks.data.getUserPerks
          .filter((perk) => perk.eligible === EligibilityStatus.Alreadyclaimed)
          .flatMap((x) => {
            return x.claims.map((claim) => {
              return {
                claimedPerkId: claim.id,
                perkId: x.perkId,
              };
            });
          }),
        potentialFraudPerkIds: userPerks.data.getUserPerks
          .filter((perk) => perk.eligible === EligibilityStatus.Potentialfraud)
          .map((x) => x.perkId),
        hasClaimedTiiCKERPerk: userPerks.data.getUserPerks.some(
          (perk) =>
            perk.eligible === EligibilityStatus.Alreadyclaimed &&
            perk.tickerSymbol.toLowerCase() === "tiicker"
        ),
        isEligibleForTiiCKERPerk: userPerks.data.getUserPerks.some(
          (perk) =>
            perk.eligible === EligibilityStatus.Eligible &&
            perk.tickerSymbol.toLowerCase() === "tiicker"
        ),
        recurring: compact(
          userPerks.data.getUserPerks
            .filter(
              (perk) =>
                perk.recurringWeekDuration &&
                perk.claims.length !== 0 &&
                perk.eligible !== EligibilityStatus.Eligible
            )
            .map((perk) => {
              const mostRecentClaim = perk.claims
                .filter((x) => x.claimedOn)
                .map((x) => x.claimedOn)
                .sort((a: TimeDateIso.Type, b: TimeDateIso.Type) =>
                  TimeDateIso.isAfter(a, b) ? 1 : -1
                )[0];

              if (mostRecentClaim && perk.recurringWeekDuration) {
                return {
                  perkId: perk.perkId,
                  recurringDate: DateIso.addWeeks(
                    DateIso.toIsoDate(TimeDateIso.parse(mostRecentClaim)),
                    perk.recurringWeekDuration
                  ),
                };
              }

              return undefined;
            })
        ),
      }));
    }
  }, [userPerks.state]);

  // Check to see if the user has a pending referral and a connected brokerage.
  // If they do, update the referral to have a status of "AVAILABLE"
  useEffect(() => {
    if (
      pendingReferralQuery.state === "DONE" &&
      pendingReferralQuery.data.getUsersPendingReferrals.length &&
      state.logins.length
    ) {
      const { id } = pendingReferralQuery.data.getUsersPendingReferrals[0];
      if (brokerageLimitHistory.state === "DONE") {
        const brokerageLimits =
          brokerageLimitHistory?.data.getAllReferralBrokerageLimits
            ?.slice()
            .reverse();
        const currentBrokerageLimit = brokerageLimits?.length
          ? brokerageLimits[0].brokerageRequirement
          : 0;
        if (state.totalPortfolioValue >= currentBrokerageLimit) {
          updatePendingReferral({
            variables: { referralId: id, makeAvailable: true },
          });
        } else {
          updatePendingReferral({
            variables: { referralId: id, makeAvailable: false },
          });
        }
      }
    }
  }, [state.logins, pendingReferralQuery, brokerageLimitHistory]);

  const refresh = async () => {
    await Promise.all([holdingsQuery.refetch(), userPerks.refetch()]);
  };

  useInterval(
    () => {
      refresh();
    },
    state.logins.some((x) => x.plaidStatus === PlaidLoginStatus.Verifying)
      ? 5000
      : null
  );

  const getLoginStatus = useQueryBundle(GetPlaidSyncStatus, {
    skip: true,
  });

  useEffect(() => {
    if (state.userId && holdingsQuery.state === "DONE") {
      holdingsQuery.data.loggedInUser?.institutionLogins.map(
        (institutionLoginData) => {
          refreshHoldings({
            variables: {
              institutionLoginId: institutionLoginData.id,
              forceUpdate: true,
            },
          });
        }
      );
    }
  }, [state.userId]);

  useEffect(() => {
    if (state.userId && holdingsQuery.state === "DONE") {
      holdingsQuery.data.loggedInUser?.institutionLogins.map(
        (institutionLoginData) => {
          getLoginStatus
            .refetch({
              institutionLoginId: institutionLoginData.id,
            })
        });
    }
  }, [state.userId]);

  useEffect(() => {
    if (holdingsQuery.state === "DONE" && holdingsQuery.data.loggedInUser) {
      const totalConnectedAccountsValue =
        holdingsQuery.data.loggedInUser?.institutionLogins
          .map((login) =>
            login.institutionAccounts.reduce(
              (total, account) =>
                total +
                (account.cashValue || 0) +
                (account.stockValue || 0) +
                (account.otherInvestmentsValue || 0),
              0
            )
          )
          .reduce((memo, a) => (memo || 0.0) + (a || 0), 0);

      setState((prevState) => ({
        ...prevState,
        userId: holdingsQuery.data.loggedInUser.id,
        logins: holdingsQuery.data.loggedInUser.institutionLogins,
        totalPortfolioValue: totalConnectedAccountsValue,
      }));
    }
  }, [holdingsQuery.state]);

  const saveState = (partialState: Partial<UserState>) => {
    setState((prevState) => ({ ...prevState, ...partialState }));
  };

  const notificationClose = () => {
    setState((prevState) => ({ ...prevState, plaidErrorDetails: undefined }));
  };

  const reportMessage = async (message?: string) => {
    if (message || state.plaidErrorDetails) {
      const errorMessage = `Error: ${JSON.stringify(
        state.plaidErrorDetails,
        null,
        2
      )}`;
      await submitPlaidConnectionError({
        variables: {
          details: message ?? errorMessage,
        },
      });
    }
  };

  return (
    <UserContext.Provider
      value={
        new UserHolder(
          state,
          saveState,
          refresh,
          generatePlaidLinkToken,
          submitPlaidConnectionError,
          addInstitutionLogin,
          fixLoginPlaidStatus
        )
      }
    >
      {flags && state.plaidErrorDetails && (
        <GlobalNotification onClose={notificationClose}>
          <PlaidErrorMessage
            onClose={notificationClose}
            reportMessage={reportMessage}
            plaidErrorDetails={state.plaidErrorDetails}
            updateTitle={() => {}}
          />
        </GlobalNotification>
      )}
      {props.children}
    </UserContext.Provider>
  );
};
