import classNames from "classnames";
import React from "react";
import styles from "./TiiDisplay.module.scss";

export interface TiiDisplayProps {
  text: string;
  size?: "thin-xs" | "xs" | "sm" | "mdsm" | "md" | "mdlg" | "lg";
  type?: "dark" | "light" | "gold";
  className?: string;
  remainSize?: boolean;
  textClassName?: string;
}

const TiiDisplay = (props: TiiDisplayProps) => {
  const textClassName =
    props.type === "dark"
      ? styles.TiiDisplay__textDark
      : props.type === "gold"
      ? styles.TiiDisplay__textGold
      : styles.TiiDisplay__textLight;

  const tiiImageSrc =
    props.type === "dark"
      ? "/images/tii-black-colon.png"
      : props.type === "gold"
      ? "/nft-assets/gold-tii.svg"
      : "/images/tii-white-colon.png";

  const displayClassName =
    props.size === undefined
      ? styles.md
      : props.size === "thin-xs"
      ? styles.thin_xs
      : props.size === "xs"
      ? styles.xs
      : props.size === "sm"
      ? styles.sm
      : props.size === "mdsm"
      ? styles.mdsm
      : props.size === "md"
      ? styles.md
      : props.size === "mdlg"
      ? styles.mdlg
      : props.size === "lg"
      ? styles.lg
      : styles.md;

  return (
    <div
      className={classNames(
        styles.TiiDisplay,
        displayClassName,
        props.className
      )}
    >
      <img
        src={tiiImageSrc}
        className={[
          styles.TiiDisplay__image,
          props.remainSize && styles.TiiDisplay__image__remain_size,
        ]
          .filter(Boolean)
          .join(" ")}
      />
      <p
        className={[props.textClassName ?? textClassName]
          .filter(Boolean)
          .join(" ")}
      >
        {props.text}
      </p>
    </div>
  );
};

export default TiiDisplay;
