import { getContentfulClient } from "@tiicker/util/lib/contentful/client";
import {
  ContentfulQuery,
  PerksForBrand,
} from "@tiicker/util/lib/contentful/queries";
import {
  ContentfulContentType,
  ContentfulKey,
  PerkDocument,
} from "@tiicker/util/lib/contentful/types";
import { ContentfulClientApi, Entry, EntryCollection, Asset } from "contentful";
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
// import { getContentfulClient } from "./client";
// import { ContentfulContentType, ContentfulKey } from "./types";
// import { ContentfulQuery } from "./queries";

class ContentfulHolder {
  readonly contentful: ContentfulClientApi;

  constructor(contentful: ContentfulClientApi) {
    this.contentful = contentful;
  }
}

const ContentfulClientContext = React.createContext<ContentfulHolder>(null!);

export const ContentfulClientProvider: React.FC<{}> = (props) => {
  return (
    <ContentfulClientContext.Provider
      value={
        new ContentfulHolder(
          getContentfulClient(process.env.CONTENTFUL_HANDLER_ID!)
        )
      }
    >
      {props.children}
    </ContentfulClientContext.Provider>
  );
};

export function useContentful(): ContentfulClientApi {
  const holder = useContext(ContentfulClientContext);
  return holder.contentful;
}

export type QueryState<T> =
  | { state: "LOADING" }
  | { state: "DONE"; result: T }
  | { state: "ERROR"; error: any };

/** Run an arbitrary query with the contentful API */
export function useContentfulQuery<T>(
  handler: (contentful: ContentfulClientApi) => Promise<T>
) {
  const contentful = useContentful();
  const [queryState, setQueryState] = React.useState<QueryState<T>>({
    state: "LOADING",
  });

  if (!contentful) {
    setQueryState({
      state: "ERROR",
      error: new Error("Contentful provider not found"),
    });
  }

  useEffect(() => {
    const promise = handler(contentful);

    promise
      .then((result) => {
        setQueryState({ state: "DONE", result });
      })
      .catch((error) => {
        console.log("Query failed with: ", error);
        setQueryState({ state: "ERROR", error });
      });
  }, [handler]);

  return queryState;
}

export type ImageOptions = {
  w?: number;
  h?: number;
  fm?: "jpg" | "png" | "webp";
  fl?: "progressive";
};

function findAsset(assetList: Asset[], assetId: string): Asset | null {
  return assetList.find((asset) => asset.sys.id === assetId) || null;
}

export function parseIncludedAssetUrl(
  assetList: Asset[],
  assetId: string,
  options: ImageOptions = {}
) {
  const asset = findAsset(assetList, assetId);

  if (!asset) {
    return undefined;
  }

  const params = Object.keys(options).reduce(
    (total, key) => (total += `${key}=${options[key]}&`),
    ""
  );

  return `${asset.fields.file.url}?${params}`;
}

type ImageAsset = {
  url: string;
  alt: string;
};
export function parseIncludedImage(
  assetList: Asset[],
  assetId: string,
  options: ImageOptions = {}
): ImageAsset | undefined {
  const asset = findAsset(assetList, assetId);

  if (!asset) {
    return undefined;
  }

  const alt = [asset.fields.title, asset.fields.description].join(" ");
  const params = Object.keys(options).reduce(
    (total, key) => (total += `${key}=${options[key]}&`),
    ""
  );

  return { url: `${asset.fields.file.url}?${params}`, alt };
}

/** Get an entry by id from Contentful */
export function useContentfulEntry<T>(entryId: string): QueryState<Entry<T>> {
  return useContentfulQuery(
    useCallback((contentful) => contentful.getEntry<T>(entryId), [entryId])
  );
}

/** Get multiple entries of a single content type */
export function useContentfulEntries<T>(
  content_type: ContentfulContentType,
  options: {
    orderBy?: string;
    query?: ContentfulQuery;
  } = {}
): QueryState<EntryCollection<T>> {
  return useContentfulQuery(
    useCallback(
      (contentful: ContentfulClientApi) =>
        contentful.getEntries<T>({
          content_type,
          order: options.orderBy,
          ...options.query,
        }),
      [content_type]
    )
  );
}

export function useContentfulEntriesNoContentType(
  ids: string
): QueryState<EntryCollection<any>> {
  return useContentfulQuery(
    useCallback(
      (contentful: ContentfulClientApi) =>
        contentful.getEntries({
          "sys.id[in]": ids,
        }),
      [ids]
    )
  );
}

export type PaginationInfo = {
  /** currentPage starts at 1 */
  currentPage: number;
  limit: number;
  totalCount: number;
  totalPages: number;
  goToPage: (page: number) => void;
};

export function usePagedContentfulEntries<T>(
  content_type: ContentfulContentType,
  options: {
    page?: number;
    limit?: number;
    orderBy?: string;
    query?: ContentfulQuery;
  }
): [QueryState<EntryCollection<T>>, PaginationInfo] {
  const [lastCount, setLastCount] = useState(0);
  const [params, setParams] = useState<{
    page: number;
    limit: number;
    totalPages?: number;
  }>({ page: options.page || 1, limit: options.limit || 10 });

  const skip = params.limit * (params.page - 1);

  const runQuery = useCallback(
    (contentful: ContentfulClientApi) =>
      contentful.getEntries<T>({
        content_type,
        order: options.orderBy,
        skip,
        limit: params.limit,
        ...options.query,
      }),
    [skip]
  );
  const result = useContentfulQuery(runQuery);

  let countFromQueryResult: number | null | undefined = null;
  if (result.state === "DONE") {
    countFromQueryResult = result.result.total;
  }
  useEffect(() => {
    if (typeof countFromQueryResult === "number")
      setLastCount(countFromQueryResult);
  }, [countFromQueryResult]);

  const count = countFromQueryResult || lastCount;
  const currentPage = params.page;
  const totalPages = Math.max(1, Math.ceil(count / params.limit));
  const paginationInfo: PaginationInfo = useMemo(
    () => ({
      currentPage,
      totalCount: count,
      limit: params.limit,
      totalPages,
      goToPage: (page) => {
        setParams({
          ...params,
          page: Math.max(1, Math.min(totalPages, page)),
        });
      },
    }),
    [setParams, currentPage, totalPages, count, params.limit]
  );

  return [result, paginationInfo];
}

export type LazyLoadInfo = {
  totalCount: number;
  showingCount: number;
  loadNextGroup: () => void;
};

export function useLazyLoadContentfulEntries<T>(
  content_type: ContentfulContentType,
  options: {
    groupSize?: number;
    orderBy?: string;
    query?: ContentfulQuery;
  }
): [QueryState<EntryCollection<T>>, LazyLoadInfo] {
  const groupSize = options.groupSize || 10;

  const [lastCount, setLastCount] = useState(0);
  const [params, setParams] = useState<{
    page: number;
    groupSize: number;
    totalPages?: number;
  }>({ page: 1, groupSize });

  const show = params.groupSize * params.page;

  const order = options.query?.order || options.orderBy;

  const runQuery = useCallback(
    (contentful: ContentfulClientApi) =>
      contentful.getEntries<T>({
        content_type,
        ...options.query,
        order,
        limit: show,
      }),
    [show, order]
  );
  const result = useContentfulQuery(runQuery);

  let countFromQueryResult: number | null | undefined = null;
  if (result.state === "DONE") {
    countFromQueryResult = result.result.total;
  }
  useEffect(() => {
    if (typeof countFromQueryResult === "number")
      setLastCount(countFromQueryResult);
  }, [countFromQueryResult]);

  const count = countFromQueryResult || lastCount;
  const currentPage = params.page;
  const totalPages = Math.max(1, Math.ceil(count / params.groupSize));
  const loadInfo: LazyLoadInfo = useMemo(
    () => ({
      totalCount: count,
      showingCount: show,
      loadNextGroup: () =>
        setParams({ ...params, page: Math.min(currentPage + 1, totalPages) }),
    }),
    [setParams, currentPage, totalPages, count, params.groupSize]
  );

  return [result, loadInfo];
}

export function useContentfulAsset(
  id: string,
  imageOptions: ImageOptions = {}
) {
  return useContentfulQuery(
    useCallback((contentful) => contentful.getAsset(id, imageOptions), [
      id,
      imageOptions,
    ])
  );
}

/** Get an entry by id from Contentful */
export function useContentfulForTypeAndKey<T>(
  content_type: ContentfulContentType,
  key: ContentfulKey | string,
  keyName: string = "key"
) {
  const fieldName = `fields.${keyName}`;
  return useContentfulQuery(
    useCallback(
      (contentful) =>
        contentful.getEntries<T>({
          content_type,
          [fieldName]: key,
        }),
      [content_type, key, keyName]
    )
  );
}

interface QueryStateReturnOptions<T> {
  doneState: (queryState: T) => ReactElement;
  loadingState?: ReactElement;
  errorState?: ReactElement;
}

export function parseQueryState<T>(
  queryState: QueryState<T>,
  returnOptions: QueryStateReturnOptions<T>
) {
  switch (queryState.state) {
    case "DONE":
      return returnOptions.doneState(queryState.result);
    case "LOADING":
      return returnOptions.loadingState;
    case "ERROR":
      return returnOptions.errorState;
    default:
      break;
  }
}

export const useGetPerksQuery = async (
  tickerSymbol?: string
): Promise<Entry<PerkDocument>[]> => {
  const perkQuery = useContentfulEntries<PerkDocument>(
    ContentfulContentType.Perk,
    {
      query: tickerSymbol
        ? PerksForBrand(tickerSymbol ?? "")
        : {
            content_type: ContentfulContentType.Perk,
            order: "fields.numberOfSharesRequired,-sys.createdAt",
          },
    }
  );

  if (perkQuery.state !== "DONE") {
    return [];
  }

  const perks = perkQuery.result.items.length > 0 ? perkQuery.result.items : [];

  const perkTierIds =
    perks.length > 0
      ? perks
          .map((perk) =>
            perk.fields.perkTierPerks && perk.fields.perkTierPerks.length > 0
              ? perk.fields.perkTierPerks.map((subPerk) => subPerk.sys.id)
              : []
          )
          .flat(1)
      : [];

  // Filter out any subPerks from being delivered in this list
  return perks.filter(
    (perk) => !perkTierIds.some((subPerk) => subPerk === perk.sys.id)
  );
};
