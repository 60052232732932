import React from "react";
import styles from "./Button.module.scss";
import classNames from "classnames";
import Link from "next/link";
import { Url } from "url";
import { Media } from "components/Media/media";

type Props = {
  children: React.ReactNode;
  arrow?: boolean;
  type?: "primary" | "secondary" | "inline";
  size?: "large" | "medium" | "small";
  submit?: boolean;
  href?: string;
  target?: "_blank";
  className?: string;
  as?: Url;
  removeArrowMobile?: boolean;
  onClick?: (e?) => void;
  noAnimate?: boolean;
  noATag?: boolean;
  disabled?: boolean;
  dataCy?: string;
  download?: string;
};

const Button = (props: Props) => {
  const typeClassName = {
    [styles.Button_Primary]: props.type === "primary" || !props.type,
    [styles.Button_Inline]: props.type === "inline",
    [styles.Button_Secondary]: props.type === "secondary",
    [styles.Button_Disabled]: props.disabled,
    [styles.Button_Arrow]: props.arrow,
  };

  const sizeClassName = {
    [styles.Button_Medium]: props.size === "medium",
    [styles.Button_Small]: props.size === "small",
  };

  const tagClassNames = classNames(
    styles.Button,
    typeClassName,
    props.className,
    sizeClassName
  );

  const Tag = (tagProps: { children: React.ReactNode }) =>
    props.submit ? (
      <button className={tagClassNames} type="submit" disabled={props.disabled}>
        {tagProps.children}
      </button>
    ) : props.noATag ? (
      <div
        onClick={props.disabled ? undefined : props.onClick}
        className={tagClassNames}
      >
        {tagProps.children}
      </div>
    ) : (
      <a
        onClick={props.disabled ? undefined : props.onClick}
        href={props.href}
        className={tagClassNames}
        target={props.target}
        download={props.download}
      >
        {tagProps.children}
      </a>
    );

  const Internal = () => (
    <Tag>
      {props.removeArrowMobile ? (
        <>
          <Media greaterThanOrEqual="md">
            <span
              // @ts-ignore
              className={classNames(styles.Button__Wrapper, {
                [styles.Button__Wrapper_Arrow]: !!props.arrow,
              })}
              data-cy={props.dataCy}
            >
              {props.children}
            </span>
          </Media>
          <Media lessThan="md">
            <span
              // @ts-ignore
              className={classNames(styles.Button__Wrapper)}
              data-cy={props.dataCy}
            >
              {props.children}
            </span>
          </Media>
        </>
      ) : (
        <span
          // @ts-ignore
          className={classNames(styles.Button__Wrapper, {
            [styles.Button__Wrapper_Arrow]: !!props.arrow,
            [styles.Button__Wrapper_NoAnimate]: !!props.noAnimate,
          })}
          data-cy={props.dataCy}
        >
          {props.children}
        </span>
      )}
    </Tag>
  );

  return props.href && !props.disabled ? (
    <Link href={props.href} as={props.as}>
      {<Internal />}
    </Link>
  ) : (
    <Internal />
  );
};

export default Button;
