import getBaseUrl from "@tiicker/util/lib/get-base-url";
import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";

interface Props {
  title: string;
  description: string;
  excludeHead?: boolean;
  routerPath?: string;
}

const MetaTags = (props: Props) => {
  const router = useRouter();
  const baseUrl = getBaseUrl();
  const currentUrl = new URL(props.routerPath || router.asPath, baseUrl);

  const tags = (
    <>
      <title>{props.title} | TiiCKER</title>
      <meta property="og:title" key="title" content={props.title} />
      <meta
        name="description"
        key="meta-description"
        content={props.description}
      />
      <meta
        property="og:description"
        key="description"
        content={props.description}
      />

      <meta property="og:url" key="url" content={currentUrl.href} />
    </>
  );

  if (props.excludeHead) {
    return tags;
  }

  return <Head>{tags}</Head>;
};

export default MetaTags;
