import React, { useCallback, useState } from "react";
import FormTextArea from "components/Form/FormTextArea";
import { useForm } from "react-hook-form";
import { PlaidErrorDetails } from "components/PlaidLink/plaidLink";
import Button from "components/core/Button/Button";
import styles from "./PlaidErrorMessage.module.scss";

type PlaidErrorForm = {
  details: string;
};

interface Props {
  onClose(): void;
  reportMessage(message?: string): void;
  updateTitle(title: string): void;
  plaidErrorDetails: PlaidErrorDetails;
}

enum PageState {
  Initial,
  NoProblem,
  HasProblem,
  SubmittedResponse,
}

const PlaidErrorMessage = (props: Props) => {
  const [pageState, setPageState] = useState<PageState>(PageState.Initial);
  const [title, setTitle] = useState<string>("Hello!");

  const errorMessage = `Error: ${JSON.stringify(
    props.plaidErrorDetails,
    null,
    2
  )}`;

  const form = useForm<PlaidErrorForm>({
    mode: "onBlur",
    submitFocusError: true,
  });

  const { handleSubmit, register, errors, formState } = form;

  const triggerTimedClose = useCallback(
    (timeout: number = 10000) => {
      setTimeout(props.onClose, timeout);
    },
    [props.onClose]
  );

  const formSubmit = useCallback(
    async (data: PlaidErrorForm) => {
      const fullDetails = data.details + "\n" + errorMessage;
      setPageState(PageState.SubmittedResponse);

      props.reportMessage(fullDetails);
      setTitle("Thank you!");
      triggerTimedClose();
    },
    [errorMessage]
  );

  const noProblemMessage = (
    <>
      <h5 className={styles.plaidErrorMessageTitle}>{title}</h5>
      <p>
        You may reach us any time at{" "}
        <a href="mailto:service@tiicker.com">service@tiicker.com</a>
      </p>
    </>
  );

  const submittedMessage = (
    <>
      <h5 className={styles.plaidErrorMessageTitle}>{title}</h5>
      <p className={styles.submittedMessage}>
        We will contact you using the email address associated with your TiiCKER
        profile.
      </p>
      <p className={styles.questionSection}>
        Questions? Contact us at{" "}
        <a href="mailto:service@tiicker.com">service@tiicker.com</a>
      </p>
    </>
  );

  const submitAccountLinkError = useCallback(async () => {
    if (props.plaidErrorDetails.error) {
      props.reportMessage(errorMessage);
    }

    setPageState(PageState.NoProblem);
    setTitle("Got it! Thank you.");
    triggerTimedClose();
  }, [props.plaidErrorDetails.error, errorMessage]);

  const brokerageConnectionIssueMessage = (
    <>
      <h5 className={styles.plaidErrorMessageTitle}>{title}</h5>
      <p>
        Looks like you did not finish connecting your brokerage account. Did you
        encounter an issue?
      </p>
      <div className={styles.brokerageConnectionErrorButtonsContainer}>
        <Button
          className={styles.errorButton}
          type="primary"
          onClick={() => {
            setTitle("Please tell us about the issue you encountered.");
            setPageState(PageState.HasProblem);
          }}
        >
          Yes
        </Button>
        <Button
          className={styles.errorButton}
          type="primary"
          onClick={submitAccountLinkError}
        >
          No
        </Button>
      </div>
    </>
  );

  const brokerageConnectionIssueFeedback = (
    <>
      <h5 className={styles.plaidErrorMessageTitle}>{title}</h5>
      <form onSubmit={handleSubmit(formSubmit)}>
        <FormTextArea
          name="details"
          title="Description of Issue"
          ref={register()}
          // className={["formField", "descriptionField"].join(" ")}
          disabled={formState.isSubmitting}
          error={errors.details && errors.details.message}
          rows={4}
        />
        <div className={styles.brokerageConnectionErrorButtonsContainer}>
          <Button type="secondary" submit className={styles.submitButton}>
            Submit
          </Button>
        </div>
      </form>
      <p className={styles.questionSection}>
        Questions? Contact us at{" "}
        <a href="mailto:service@tiicker.com">service@tiicker.com</a>
      </p>
    </>
  );

  switch (pageState) {
    case PageState.Initial:
      return brokerageConnectionIssueMessage;
    case PageState.NoProblem:
      return noProblemMessage;
    case PageState.HasProblem:
      return brokerageConnectionIssueFeedback;
    case PageState.SubmittedResponse:
      return submittedMessage;
  }
};

export default PlaidErrorMessage;
