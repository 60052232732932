import Banner from "components/Banner/Banner";
import Cookies from "js-cookie";
import { useContentfulEntries } from "source/contentful/provider";
import {
  Banner as BannerDocument,
  ContentfulContentType,
} from "@tiicker/util/lib/contentful/types";
import React, { useState } from "react";
import NftBanner from "components/Nft/NftBanner/NftBanner";
import { useRouter } from "next/router";
import styles from "./GlobalBanner.module.scss";

interface Props {}

const GlobalBanner = (props: Props) => {
  const router = useRouter();

  const globalBannerCookieName = "hasClosedGlobalBanner";

  const bannerCookie = Cookies.get(globalBannerCookieName);

  const [hasClosed, setHasClosed] = useState(false);

  const bannerDocument = useContentfulEntries<BannerDocument>(
    ContentfulContentType.Banner,
    {
      query: {
        content_type: ContentfulContentType.Banner,
        "fields.title[in]": "Global Banner,Nft Banner",
      },
    }
  );

  // Hide if we have the global banner cookie closed unless we load the nft banner
  if (
    bannerDocument.state !== "DONE" ||
    bannerDocument.result.items.length === 0 ||
    (bannerCookie === "true" &&
      !bannerDocument.result.items.some(
        (x) => x.fields.title === "Nft Banner"
      )) ||
    hasClosed ||
    router.pathname.indexOf("nft") >= 0 ||
    router.pathname.toLowerCase().endsWith("niri")
  ) {
    return <></>;
  }

  const onClick = (e) => {
    Cookies.set(globalBannerCookieName, "true");
    setHasClosed(true);
    e.stopPropagation();
  };

  const nftBanner = bannerDocument.result.items.find(
    (x) => x.fields.title === "Nft Banner"
  );

  const banner = bannerDocument.result.items.find(
    (x) => x.fields.title === "Global Banner"
  );

  return (
    <>
      {nftBanner && <NftBanner banner={nftBanner} onClose={onClick} />}
      {banner && !nftBanner && (
        <Banner
          className={styles.GlobalBanner}
          title={banner.fields.displayTitle}
          linkText={banner.fields.linkText}
          linkUrl={banner.fields.linkUrl}
          bodyText={banner.fields.bodyText}
          onClick={onClick}
          onClose={onClick}
          linkTarget="default"
        />
      )}
    </>
  );
};

export default GlobalBanner;
