import React, { useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { GetLoggedInUser } from "$gql/queries/general/GetLoggedInUser.gen";
import { useQuery } from "@apollo/client";
import { UserFragment } from "$gql/fragments/general/User.gen";
import { useRouter } from "next/router";
import { CookieName, useCookie } from "source/hooks";
import * as Sentry from "@sentry/nextjs";
import { FlagsProvider } from "./FlagsProvider";

interface Props {
  children: React.ReactNode;
}

export type UserState =
  | {
      user: UserFragment | null;
      admin: boolean;
      sales: boolean;
      loading: false;
      refresh(): void;
    }
  | {
      user: null | undefined;
      admin: false;
      sales: false;
      loading: true;
      refresh: undefined;
    };

const initialState: UserState = {
  user: undefined,
  loading: true,
  admin: false,
  sales: false,
  refresh: undefined,
};

export const UserContext = React.createContext<UserState>(initialState);

export const useFetchUser = () => {
  return useContext(UserContext);
};

const UserWrapper = (props: Props) => {
  const { user, isLoading } = useAuth0();
  const authCookie = useCookie<string>(CookieName.AuthCookie);

  const [userState, setUserState] = React.useState<UserState>(initialState);
  const userBundle = useQuery<GetLoggedInUser.Query, GetLoggedInUser.Variables>(
    GetLoggedInUser.Document,
    {
      skip: true,
    }
  );

  const refresh = () => {
    console.log("Refresh");
    userBundle.refetch().then((r) => {
      console.log("NEW STATE", r.data.loggedInUser);

      if (!r.data.loggedInUser?.email) {
        return;
      }

      setUserState({
        ...userState,
        user: r.data.loggedInUser,
        admin:
          r.data.loggedInUser.roles?.some((x) => x.toLowerCase() === "admin") ||
          false,
        sales:
          r.data.loggedInUser.roles?.some((x) => x.toLowerCase() === "sales") ||
          false,
        loading: false,
        refresh,
      });
    });
  };

  const router = useRouter();

  useEffect(() => {
    // if (!isLoading && userState.loading && !authCookie) {
    //   setUserState({ loading: false, user: null, admin: false });
    // }

    if (userState.user !== undefined || ((!user || isLoading) && !authCookie)) {
      if (!authCookie && !user && !isLoading && userState.loading) {
        setUserState({
          loading: false,
          user: null,
          admin: false,
          sales: false,
          refresh,
        });
      }

      return;
    }

    const fetchData = async () => {
      setUserState({
        loading: true,
        admin: false,
        sales: false,
        user: null,
        refresh: undefined,
      });

      try {
        const result = await userBundle.refetch();

        if (result.data.loggedInUser?.email) {
          Sentry.setUser({ email: result.data.loggedInUser?.email });

          if (
            !result.data.loggedInUser.isVerified &&
            process.env.SALES_MODE !== "active" &&
            !router.asPath.includes("onboarding/step")
          ) {
            const url = `/onboarding/step/verify`;
            router.replace(`/redirect?url=${url}`);
          }

          setUserState({
            user: result.data.loggedInUser,
            admin:
              result.data.loggedInUser.roles?.some(
                (x) => x.toLowerCase() === "admin"
              ) || false,
            sales:
              result.data.loggedInUser.roles?.some(
                (x) => x.toLowerCase() === "sales"
              ) || false,
            loading: false,
            refresh,
          });
        } else {
          setUserState({
            user: null,
            admin: false,
            sales: false,
            loading: false,
            refresh,
          });
        }
      } catch (e) {
        setUserState({
          user: null,
          admin: false,
          sales: false,
          loading: false,
          refresh,
        });
      }
    };

    fetchData();
  }, [userState, user?.sub, isLoading]);

  return (
    <FlagsProvider>
      <UserContext.Provider value={userState}>
        {props.children}
      </UserContext.Provider>
    </FlagsProvider>
  );
};

export default UserWrapper;
