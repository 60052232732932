import { GeneratePlaidLinkToken } from "$gql/mutations/general/GeneratePlaidLinkToken.gen";
import { useLayoutEffect, useState } from "react";
import { useMutationBundle } from "source/hooks";

interface Options {
  onLoad?: () => void;
  onSuccess?: (publicToken, metadata) => void;
  onExit?: (err, metadata) => void;
  onEvent?: (eventName, metadata) => void;
  token?: string;
  receivedRedirectUri?: string;
}

export interface PlaidAPI {
  open: () => void;
  initialized: boolean;
}

export default function createPlaid(handlers: Options): PlaidAPI | null {
  if (typeof window === "undefined") {
    return null;
  }

  // @ts-ignore
  return window.Plaid.create({
    apiVersion: "v2",
    clientName: "TiiCKER",
    env: process.env.PLAID_ENV,
    product: "investments",
    countryCodes: "US",
    ...handlers,
  });
}
