import Head from "next/head";
import React from "react";

interface Props {
  image: string;
  imageAlt: string;
  excludeHead?: boolean;
}

const MetaImage = (props: Props) => {
  const tags = (
    <>
      <meta name="twitter:image:alt" key="imagealt" content={props.imageAlt} />
      <meta property="og:image" key="image" content={props.image} />
    </>
  );

  if (props.excludeHead) {
    return tags;
  }
  return <Head>{tags}</Head>;
};

export default MetaImage;
