import { Entry } from "contentful";
import { Banner as BannerDocument } from "@tiicker/util/lib/contentful/types";
import styles from "./NftBanner.module.scss";
import TiiDisplay from "components/TiiDisplay/TiiDisplay";
import { useRouter } from "next/router";
import { useEffect } from "react";

type Props = {
  banner: Entry<BannerDocument>;
  onClose?(e): void;
};

const NftBanner = (props: Props) => {
  const router = useRouter();

  if (router.asPath.indexOf("nft") > -1) {
    return <></>;
  }

  useEffect(() => {
    router.prefetch("/nft/search");
  }, []);

  const click = () => {
    router.push("/nft/search");
  };

  return (
    <div
      className={[styles.Banner, styles.GlobalBanner].filter(Boolean).join(" ")}
    >
      <div className={styles.nftBanner__flexColumn}>
        <div className={styles.nftBanner__container} onClick={click}>
          {/* {props.onClose && ( */}
          <div className={styles.nftBanner__x} onClick={props.onClose}>
            ✕
          </div>
          {/* )} */}
          <div className={styles.nftBanner__content}>
            {props.banner.fields.title && (
              <span className={styles.nftBanner__title}>
                {props.banner.fields.bodyText}
              </span>
            )}
            <span className={styles.nftBanner__text}>
              Claim your <TiiDisplay size="sm" text={""}></TiiDisplay>MARK
            </span>
            {/* <a href="/nft/search" className={styles.nftBanner__link}>
              {">"}
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NftBanner;
