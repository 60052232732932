import * as React from "react";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

interface Props {
  containerSelector: string;
  children: React.ReactNode;
}

// Influenced heavily from https://stackoverflow.com/a/66212708
const Portal: React.FC<Props> = ({ children, containerSelector }: Props) => {
  const [container, setContainer] = useState<HTMLDivElement | undefined>();

  useEffect(() => {
    const possibleContainer =
      document.querySelector<HTMLDivElement>(containerSelector);

    if (possibleContainer) {
      setContainer(possibleContainer);
    }
  }, []);

  if (!container) {
    return <></>;
  }

  return createPortal(children, container);
};

export default Portal;
