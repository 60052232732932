export type Option = {
  label: string;
  value: string;
};

export const GenderDropdownOptions: Option[] = [
  { value: "Female", label: "Female" },
  { value: "Male", label: "Male" },
  { value: "Other", label: "Other" },
];
export const MaritalStatusDropdownOptions: Option[] = [
  { value: "", label: "" },
  { value: "Single", label: "Single" },
  { value: "Married", label: "Married" },
  { value: "Separated", label: "Separated" },
  { value: "Divorced", label: "Divorced" },
  { value: "Widowed", label: "Widowed" },
];

export const EducationDropdownOptions: Option[] = [
  { value: "", label: "" },
  { value: "Some High School", label: "Some High School" },
  { value: "Associate", label: "Associate" },
  { value: "Bachelor", label: "Bachelor" },
  { value: "Master", label: "Master" },
  { value: "PhD", label: "PhD" },
  { value: "Skilled Trade", label: "Skilled Trade" },
  { value: "GED", label: "GED" },
];
export const HouseholdIncomeDropdownOptions: Option[] = [
  { value: "", label: "" },
  { value: "$0 - $50,000", label: "$0 - $50,000" },
  { value: "$50,001 - $100,000", label: "$50,000 - $100,000" },
  { value: "$100,001 - $150,000", label: "$100,001 - $150,000" },
  { value: "$150,001 - $250,000", label: "$150,001 - $250,000" },
  { value: "> $250,000", label: "> $250,000" },
];

export const InvesibleIncomeDropdownOptions: Option[] = [
  { value: "", label: "" },
  { value: "$0 - $5,000", label: "$0 - $5,000" },
  { value: "$5,001 - $10,000", label: "$5,001 - $10,000" },
  { value: "$10,001 - $25,000", label: "$10,001 - $25,000" },
  { value: "$25,001 - $100,000", label: "$25,001 - $100,000" },
  { value: "> $100,000", label: "> $100,000" },
];

export const NumberOfChildrenDropdownOptions: Option[] = [
  { value: "", label: "" },
  { value: "0", label: "0" },
  { value: "1-2", label: "1-2" },
  { value: "3-4", label: "3-4" },
  { value: "5-6", label: "5-6" },
  { value: "7+", label: "7+" },
];

export const PetsDropdownOptions: Option[] = [
  { value: "Dogs", label: "Dog(s)" },
  { value: "Cats", label: "Cats(s)" },
  { value: "Other", label: "Other" },
];

export const OccupationDropdownOptions: Option[] = [
  { value: "", label: "" },
  { value: "Accounting", label: "Accounting" },
  { value: "Architecture", label: "Architecture" },
  { value: "Art & Design", label: "Art & Design" },
  {
    value: "Building & Grounds Maintenance",
    label: "Building & Grounds Maintenance",
  },
  { value: "Business", label: "Business" },
  {
    value: "Computer Sciences & Technology",
    label: "Computer Sciences & Technology",
  },
  { value: "Construction", label: "Construction" },
  { value: "Education", label: "Education" },
  { value: "Engineering", label: "Engineering" },
  { value: "Entertainment & Sports", label: "Entertainment & Sports" },
  { value: "Executive", label: "Executive" },
  {
    value: "Farming, Fishing, & Forestry",
    label: "Farming, Fishing, & Forestry",
  },
  { value: "Finance", label: "Finance" },
  { value: "Healthcare", label: "Healthcare" },
  { value: "Legal", label: "Legal" },
  { value: "Management", label: "Management" },
  { value: "Mathematical Occupations", label: "Mathematical Occupations" },
  { value: "Media & Public Relations", label: "Media & Public Relations" },
  { value: "Office & Administrative", label: "Office & Administrative" },
  { value: "Personal Care & Services", label: "Personal Care & Services" },
  { value: "Production & Factory", label: "Production & Factory" },
  { value: "Sales", label: "Sales" },
  { value: "Service Industry", label: "Service Industry" },
  { value: "Social Services", label: "Social Services" },
  { value: "Transportation", label: "Transportation" },
  { value: "Other", label: "Other" },
];

export const StateDropdownOptions: Option[] = [
  { value: "", label: "" },
  { label: "Alabama", value: "Alabama" },
  { label: "Alaska", value: "Alaska" },
  { label: "Arizona", value: "Arizona" },
  { label: "Arkansas", value: "Arkansas" },
  { label: "California", value: "California" },
  { label: "Colorado", value: "Colorado" },
  { label: "Connecticut", value: "Connecticut" },
  { label: "Delaware", value: "Delaware" },
  { label: "Florida", value: "Florida" },
  { label: "Georgia", value: "Georgia" },
  { label: "Hawaii", value: "Hawaii" },
  { label: "Idaho", value: "Idaho" },
  { label: "Illinois", value: "Illinois" },
  { label: "Indiana", value: "Indiana" },
  { label: "Iowa", value: "Iowa" },
  { label: "Kansas", value: "Kansas" },
  { label: "Kentucky", value: "Kentucky" },
  { label: "Louisiana", value: "Louisiana" },
  { label: "Maine", value: "Maine" },
  { label: "Maryland", value: "Maryland" },
  { label: "Massachusetts", value: "Massachusetts" },
  { label: "Michigan", value: "Michigan" },
  { label: "Minnesota", value: "Minnesota" },
  { label: "Mississippi", value: "Mississippi" },
  { label: "Missouri", value: "Missouri" },
  { label: "Montana", value: "Montana" },
  { label: "Nebraska", value: "Nebraska" },
  { label: "Nevada", value: "Nevada" },
  { label: "New Hampshire", value: "New Hampshire" },
  { label: "New Jersey", value: "New Jersey" },
  { label: "New Mexico", value: "New Mexico" },
  { label: "New York", value: "New York" },
  { label: "North Carolina", value: "North Carolina" },
  { label: "North Dakota", value: "North Dakota" },
  { label: "Ohio", value: "Ohio" },
  { label: "Oklahoma", value: "Oklahoma" },
  { label: "Oregon", value: "Oregon" },
  { label: "Pennsylvania", value: "Pennsylvania" },
  { label: "Rhode Island", value: "Rhode Island" },
  { label: "South Carolina", value: "South Carolina" },
  { label: "South Dakota", value: "South Dakota" },
  { label: "Tennessee", value: "Tennessee" },
  { label: "Texas", value: "Texas" },
  { label: "Utah", value: "Utah" },
  { label: "Vermont", value: "Vermont" },
  { label: "Virginia", value: "Virginia" },
  { label: "Washington", value: "Washington" },
  { label: "West Virginia", value: "West Virginia" },
  { label: "Wisconsin", value: "Wisconsin" },
  { label: "Wyoming", value: "Wyoming" },
  { label: "Washington DC", value: "Washington DC" },
];

export const CanadianProvinces: Option[] = [
  { value: "", label: "" },
  { value: "Alberta", label: "Alberta" },
  { value: "British Columbia", label: "British Columbia" },
  { value: "Manitoba", label: "Manitoba" },
  { value: "New Brunswick", label: "New Brunswick" },
  { value: "Newfoundland and Labrador", label: "Newfoundland and Labrador" },
  { value: "Northwest Territories", label: "Northwest Territories" },
  { value: "Nova Scotia", label: "Nova Scotia" },
  { value: "Nunavut", label: "Nunavut" },
  { value: "Ontario", label: "Ontario" },
  { value: "Prince Edward Island", label: "Prince Edward Island" },
  { value: "Quebec", label: "Quebec" },
  { value: "Saskatchewan", label: "Saskatchewan" },
  { value: "Yukon", label: "Yukon" },
];

export const MexicanStates: Option[] = [
  { value: "", label: "" },
  { value: "Aguascalientes", label: "Aguascalientes" },
  { value: "Baja California", label: "Baja California" },
  { value: "Baja California Sur", label: "Baja California Sur" },
  { value: "Campeche", label: "Campeche" },
  { value: "Chiapas", label: "Chiapas" },
  { value: "Chihuahua", label: "Chihuahua" },
  { value: "Ciudad de México", label: "Ciudad de México" },
  { value: "Coahuila", label: "Coahuila" },
  { value: "Colima", label: "Colima" },
  { value: "Durango", label: "Durango" },
  { value: "Guanajuato", label: "Guanajuato" },
  { value: "Guerrero", label: "Guerrero" },
  { value: "Hidalgo", label: "Hidalgo" },
  { value: "Jalisco", label: "Jalisco" },
  { value: "México", label: "México" },
  { value: "Michoacán", label: "Michoacán" },
  { value: "Morelos", label: "Morelos" },
  { value: "Nayarit", label: "Nayarit" },
  { value: "Nuevo León", label: "Nuevo León" },
  { value: "Oaxaca", label: "Oaxaca" },
  { value: "Puebla", label: "Puebla" },
  { value: "Querétaro", label: "Querétaro" },
  { value: "Quintana Roo", label: "Quintana Roo" },
  { value: "San Luis Potosí", label: "San Luis Potosí" },
  { value: "Sinaloa", label: "Sinaloa" },
  { value: "Sonora", label: "Sonora" },
  { value: "Tabasco", label: "Tabasco" },
  { value: "Tamaulipas", label: "Tamaulipas" },
  { value: "Tlaxcala", label: "Tlaxcala" },
  { value: "Veracruz", label: "Veracruz" },
  { value: "Yucatán", label: "Yucatán" },
  { value: "Zacatecas", label: "Zacatecas" },
];

export const AustralianStates: Option[] = [
  { value: "Ashmore and Cartier Islands", label: "Ashmore and Cartier Islands" },
  { value: "Australian Antarctic Territory", label: "Australian Antarctic Territory" },
  { value: "Australian Capital Territory", label: "Australian Capital Territory" },
  { value: "Christmas Island", label: "Christmas Island" },
  { value: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands" },
  { value: "Coral Sea Islands", label: "Coral Sea Islands" },
  { value: "Heard Island and McDonald Islands", label: "Heard Island and McDonald Islands" },
  { value: "Jervis Bay Territory", label: "Jervis Bay Territory" },
  { value: "New South Wales", label: "New South Wales" },
  { value: "Norfolk Island", label: "Norfolk Island" },
  { value: "Northern Territory", label: "Northern Territory" },
  { value: "Queensland", label: "Queensland" },
  { value: "South Australia", label: "South Australia" },
  { value: "Tasmania", label: "Tasmania" },
  { value: "Victoria", label: "Victoria" },
  { value: "Western Australia", label: "Western Australia" },
];

export const CountryOptions: Option[] = [
  { value: "United States", label: "United States" },
  { value: "Australia", label: "Australia" },
  { value: "Canada", label: "Canada" },
  { value: "Mexico", label: "Mexico" },
];

export const EthnicityOptions: Option[] = [
  { value: "", label: "" },
  {
    label: "American Indian or Alaskan Native",
    value: "American Indian or Alaskan Native",
  },
  { label: "Asian", value: "Asian" },
  { label: "Black or African American", value: "Black or African American" },
  { label: "Hispanic", value: "Hispanic" },
  {
    label: "Native Hawaiian or other Pacific Islander",
    value: "Native Hawaiian or other Pacific Islander",
  },
  { label: "White or Caucasian", value: "White or Caucasian" },
  { label: "Other", value: "Other" },
];

export const DayOptions = (): Option[] => {
  return Array.from({ length: 31 }).map((_, i) => {
    const value = (i + 1).toString();
    return { label: value, value };
  });
};

export const YearOptions = (): Option[] => {
  const currentYear = new Date().getUTCFullYear();

  return Array.from({ length: 100 }).map((_, i) => {
    const value = (currentYear - i).toString();
    return { label: value, value };
  });
};

export const MonthConversions = {
  "0": "January",
  "1": "February",
  "2": "March",
  "3": "April",
  "4": "May",
  "5": "June",
  "6": "July",
  "7": "August",
  "8": "September",
  "9": "October",
  "10": "November",
  "11": "December",
};

export const MonthOptions: Option[] = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
];

export const BirthdateRange: Option[] = [
  { value: "18-24", label: "18-24" },
  { value: "25-30", label: "25-30" },
  { value: "31-35", label: "31-35" },
  { value: "36-40", label: "36-40" },
  { value: "41-44", label: "41-44" },
  { value: "45-49", label: "45-49" },
  { value: "50-54", label: "50-54" },
  { value: "55-59", label: "55-59" },
  { value: "60-65", label: "60-65" },
  { value: "65+", label: "65+" },
];

export const HowDidYouFindUsOptions: Option[] = [
  { value: "", label: "" },
  { value: "Referral", label: "Referral" },
  { value: "Google", label: "Google" },
  { value: "Another website", label: "Another website" },
  { value: "Social media", label: "Social media" },
  { value: "Digital ad", label: "Digital ad" },
  { value: "Print ad", label: "Print ad" },
  { value: "In-Person event", label: "In-Person event" },
  { value: "News article or blog", label: "News article or blog" },
  { value: "Other", label: "Other (fill in blank)" },
];
