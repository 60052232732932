import React from "react";
import { Message } from "react-hook-form";
import styles from "./Form.module.scss";
import FormElementLabelAndError from "./FormElementLabel";

type Props = {
  name: string;
  required?: boolean;
  label?: string;
  error?: string | Message | null;
  rows?: number;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

const FormTextArea: React.FC<Props> = React.forwardRef((props, ref) => {
  return (
    <FormElementLabelAndError {...props}>
      <div>
        <textarea {...props} className={styles.input} ref={ref} />
      </div>
    </FormElementLabelAndError>
  );
});

export default FormTextArea;
