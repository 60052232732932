import classNames from "classnames";
import React from "react";
import { Message } from "react-hook-form";
import styles from "./Form.module.scss";

export type DisplayOptions = {
  inline?: boolean;
  hideRequiredIndicator?: boolean;
};

export type FormElementLabelProps = {
  name: string;
  required?: boolean;
  label?: string;
  error?: string | Message | null;
  className?: string;

  displayOptions?: DisplayOptions;
};

const FormElementLabelAndError: React.FC<FormElementLabelProps> = (props) => {
  const displayOptions = props.displayOptions || {};
  return (
    <div
      className={classNames(
        styles.formField,
        props.className,
        displayOptions.inline && styles.inputInline
      )}
    >
      {props.label && (
        <div
          className={classNames(
            props.required &&
              !displayOptions.hideRequiredIndicator &&
              styles.required,
            styles.labelContainer
          )}
        >
          <label htmlFor={props.name} className={styles.label}>
            {props.label}
          </label>
        </div>
      )}

      <div className={styles.inputWrapper}>{props.children}</div>

      <div className={styles.error}>{props.error}</div>
    </div>
  );
};

export default FormElementLabelAndError;
