import { useRouter } from "next/router";
import React, { useState } from "react";
import { SubmitPlaidAccountConnectionErrorForm } from "$gql/mutations/general/SubmitPlaidAccountConnectionErrorForm.gen";
import { useMutationBundle } from "source/hooks";
import PlaidErrorMessage from "components/PlaidLink/PlaidErrorMessage";
import GlobalNotification from "components/GlobalNotification/GlobalNotification";

type Props = {};

const TDError = (props: Props) => {
  const [shownMessage, setShownMessage] = useState<boolean>(false);
  const router = useRouter();

  const key = `open${Date.now()}`;

  const { tdOauthError } = router.query;

  React.useEffect(() => {
    if (tdOauthError && !shownMessage) {
      updateTitle("Issue connecting your TD Ameritrade account?");
      setShownMessage(true);
    }
  }, [tdOauthError, shownMessage]);

  // const closeNotification = () => {
  //   notification.close(key);
  // };

  const [submitConnectionError] = useMutationBundle(
    SubmitPlaidAccountConnectionErrorForm
  );

  const reportMessage = async (message?: string) => {
    await submitConnectionError({
      variables: {
        details: `TD Oauth canceled or error: ${message}`,
      },
    });
  };

  const updateTitle = (title: string) => {
    // notification.open({
    //   className: "connectionIssueModal",
    //   key,
    //   message: title,
    //   duration: 0,
    //   description: (
    //     <PlaidErrorMessage
    //       onClose={closeNotification}
    //       reportMessage={reportMessage}
    //       plaidErrorDetails={{}}
    //       updateTitle={updateTitle}
    //     />
    //   ),
    //   placement: "topRight",
    //   onClose: () => {
    //     closeNotification();
    //   },
    //   style: {
    //     width: 500,
    //   },
    // });
  };

  return (
    <>
      {shownMessage && (
        <GlobalNotification onClose={() => {}}>
          <PlaidErrorMessage
            onClose={() => {}}
            reportMessage={reportMessage}
            plaidErrorDetails={{}}
            updateTitle={() => {}}
          />
        </GlobalNotification>
      )}
    </>
  );
};

export default TDError;
