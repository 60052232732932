
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AnswerQuestionResponse": [
      "QuestionResponse",
      "AuthenticateSuccessResponse"
    ],
    "AuthenticateResponse": [
      "AuthenticateSuccessResponse",
      "QuestionResponse",
      "AuthenticateErrorResponse"
    ],
    "CreateInstitutionLoginResponse": [
      "InstitutionLogin",
      "GeneralError"
    ],
    "EquityTradePreviewResponse": [
      "EquityTradePreview",
      "EquityTradePreviewError",
      "EquityTradeFaultError"
    ]
  }
};
      export default result;
    