import Button from "components/core/Button/Button";
import GlobalNotification from "components/GlobalNotification/GlobalNotification";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

interface Props {}

const Notification = (props: Props) => {
  // Generic Notification setup, build separate triggers for your notification
  const [navigationCount, setNavigationCount] = useState(0);
  const [firstPromoShowCount, setFirstPromoShowCount] = useState<
    number | undefined
  >();
  const router = useRouter();
  useEffect(() => {
    setNavigationCount(navigationCount + 1);
  }, [router.asPath]);

  // Promo Referral reminder, in case they haven't redeemed it
  const cookieShowCount = Cookies.get("promoShowCount");
  const [promoShowCount, setPromoShowCount] = useState(
    cookieShowCount ? parseInt(cookieShowCount) : 0
  );
  const promoReferralCookie = Cookies.get("promoReferral");
  const promoNotificationKey = `promo-referral-${promoReferralCookie}`;
  const blockListedPaths = ["/refer/promo/", "/rewards/promo", "onboarding"];

  useEffect(() => {
    if (cookieShowCount && firstPromoShowCount === undefined) {
      setFirstPromoShowCount(Number(cookieShowCount));
    }
  }, [cookieShowCount]);

  useEffect(() => {
    if (
      (navigationCount === 3 || navigationCount === 5) &&
      promoReferralCookie &&
      !blockListedPaths.some((path) => router.asPath.includes(path)) &&
      promoShowCount === 0
    ) {
      Cookies.set("promoShowCount", (promoShowCount + 1).toString());
      setPromoShowCount(promoShowCount + 1);
    }
  }, [
    navigationCount,
    promoReferralCookie,
    promoNotificationKey,
    router.asPath,
  ]);

  return (
    <div>
      {(navigationCount === 3 || navigationCount === 5) &&
        promoReferralCookie &&
        !blockListedPaths.some((path) => router.asPath.includes(path)) &&
        firstPromoShowCount === 0 && (
          <GlobalNotification onClose={() => {}}>
            <p>You have a gift waiting, do you want to claim that now?</p>
            <Button type="primary" href={`/refer/promo/${promoReferralCookie}`}>
              Claim Now!
            </Button>
          </GlobalNotification>
        )}
    </div>
  );
};

export default Notification;
