import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  from,
  NormalizedCacheObject,
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { buildErrorLink } from "./error-link";
// import getBaseUrl from "../get-base-url";

import introspectionResult from "../graphql/introspection-results";
import getBaseUrl from "@tiicker/util/lib/get-base-url";
import Cookies from "js-cookie";

import * as nodeFetch from "node-fetch";
import { abortableFetch } from "abortcontroller-polyfill/dist/cjs-ponyfill";
global.fetch = abortableFetch(nodeFetch).fetch;

interface Options {
  baseURL?: string;
  getAuthToken?: () => Promise<string | undefined>;
}

export const TEST_COOKIE_NAME = "tiicker-test-auth-id";

let graphqlClient: ApolloClient<NormalizedCacheObject> | undefined;

export const buildGraphqlClient = (options?: Options) => {
  // If we dont auth token and we already have an instance built we return that one
  if (!options?.getAuthToken && graphqlClient) {
    console.log("SERVING GRAPHQL CLIENT");
    return graphqlClient;
  }

  const errorLink: ApolloLink = buildErrorLink();

  // Try to find a base URL. This invocation does not have access to a request
  // (which may be present if we're on the server.) In that case, we will
  // hopefully already have a base URL provided in our options which we will
  // fall back on.

  let link: ApolloLink | null = null;

  const authLink = setContext(async () => {
    const token = options?.getAuthToken
      ? await options.getAuthToken()
      : undefined;
    const vuid = Cookies.get("vuid");
    const sessionCookie = Cookies.get("sessionCookie");

    const testUserAuth =
      process.env.NODE_ENV === "test"
        ? Cookies.get(TEST_COOKIE_NAME)
        : undefined;

    return {
      headers: {
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        "x-vuid": vuid,
        "x-session-cookie": sessionCookie,
        [TEST_COOKIE_NAME]: testUserAuth,
      },
    };
  });

  if (typeof window === "undefined") {
    // server: use configured base URL and pass along credentials
    let baseUrl = options?.baseURL;
    if (!baseUrl) {
      baseUrl = getBaseUrl()?.href;
      // console.log("no base URL, inferring", baseUrl);
    } else {
      // console.log("using base URL from prior", baseUrl);
    }

    const uri = new URL("/api/graphql", baseUrl).href;

    const links: ApolloLink[] = [
      errorLink,
      authLink,
      new BatchHttpLink({
        uri,
        batchInterval: 10,
      }) as unknown as ApolloLink,
    ];
    link = from(links);
  } else {
    // client: batch, use relative URL
    let baseUrl = options?.baseURL;
    if (!baseUrl) {
      baseUrl = getBaseUrl()?.href;
      // console.log("no base URL, inferring", baseUrl);
    } else {
      // console.log("using base URL from prior", baseUrl);
    }

    const links: ApolloLink[] = [
      errorLink,
      authLink,
      new BatchHttpLink({
        uri: new URL("/api/graphql", baseUrl).href,
        batchInterval: 10,
      }) as unknown as ApolloLink,
    ];
    link = from(links);
  }

  const client = new ApolloClient({
    ssrMode: typeof window === "undefined",
    link,

    // Tell the client the possible types of UNION types
    cache: new InMemoryCache({
      possibleTypes: introspectionResult.possibleTypes,
    }),

    defaultOptions: {
      watchQuery: {
        // this governs the default fetch policy for react-apollo useQuery():
        fetchPolicy: "cache-and-network",
      },
    },
  });

  // If we dont have the auth token option present we keep this client around
  if (!options?.getAuthToken) {
    graphqlClient = client;
  }

  console.log(
    "BUILDING NEW GRAPHQL CLIENT",
    options?.getAuthToken !== undefined
  );

  return client;
};
