import { createMedia } from "@artsy/fresnel";

export const AppMedia = createMedia({
  breakpoints: {
    // The lower bound for being considered "small"
    sm: 0,

    // These values should match the Breakpoint values
    // in styles/themeless
    mdsm: 576,
    md: 768,
    lg: 992,
    lgxl: 1100,
    xl: 1440,
  },
});

export const { MediaContextProvider, Media } = AppMedia;

export const mediaStyle = AppMedia.createMediaStyle();
