import { useMutation } from "@apollo/client";
import { uniq } from "lodash";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useQueryBundle, useSession } from "source/hooks";
import { CreateOrUpdateOrder } from "$gql/mutations/general/CreateOrUpdateOrder.gen";
import { GetActiveCart } from "$gql/queries/general/GetActiveCart.gen";
import { ProductType } from "$gql/types.gen";
import * as TimeDateIso from "@tiicker/util/lib/date-iso/time-date-iso";
import { PositionProperties } from "../../Nft/NftStoreImage/NftStoreImage";
import { TiiColorChoices } from "@tiicker/util/lib/contentful/types";

interface CartApi {}

export type CartItem = {
  quantity: number;
  title: string;
  productType: ProductType;
  nftId?: number;
  productId?: string;
  colorId?: string;
  sizeId?: string;
  variationId?: number;
  color?: string;
  size?: string;
  dateAdded?: TimeDateIso.Type;
  svg?: string;
  positionProps?: PositionProperties;
  price?: number;
  nftName?: string;
  backgroundId?: number;
  patternId?: number;
  premiumBackground?: boolean;
};

interface CartState {
  cartItems: CartItem[];
  cartIsOpen: boolean;
  sessionId: string;
  isLoadingCart: boolean;
  subtotal: number;
  hasNft: boolean;
}

export class CartHolder implements CartApi {
  readonly state: CartState;
  private setState: (state: Partial<CartState>) => void;
  readonly updateOrder: (options?: {
    variables: CreateOrUpdateOrder.Variables;
  }) => Promise<any>;

  constructor(
    state: CartState,
    setState: (state: Partial<CartState>) => void,
    updateOrder: (options?: {
      variables: CreateOrUpdateOrder.Variables;
    }) => Promise<any>
  ) {
    this.state = state;
    this.setState = setState;
    this.updateOrder = updateOrder;
  }

  toggleCart() {
    this.setState({
      cartIsOpen: !this.state.cartIsOpen,
    });
  }

  clearCart() {
    this.setState({
      cartItems: [],
      subtotal: undefined,
    });
  }

  addQuantity(item: CartItem, numberToAdd: number) {
    const allOtherItems = this.state.cartItems.filter((ci) => {
      return !(
        ci.productId === item.productId &&
        ci.colorId === item.colorId &&
        ci.sizeId === item.sizeId &&
        ci.nftId === item.nftId &&
        ci.title === item.title
      );
    });

    const quantity = item.quantity + numberToAdd;

    const orderItems = [
      ...allOtherItems,
      {
        ...item,
        quantity,
        price: ((item.price ?? 0) / item.quantity) * quantity,
      },
    ];

    const subtotal = orderItems
      .map((x) => x.price ?? 0)
      .reduce((a, b) => a + b);

    this.setState({
      cartItems: orderItems,
      subtotal,
    });

    this.updateOrder({
      variables: {
        sessionId: this.state.sessionId,
        orderItems: orderItems.map((item) => {
          return {
            productId: item.productId,
            quantity: item.quantity,
            colorId: item.colorId,
            sizeId: item.sizeId,
            variationId: item.variationId,
            title: item.title,
            size: item.size ?? "",
            color: item.color ?? "",
            productType: item.productType,
            nftId: item.nftId,
            svg: item.svg,
            positionProps: item.positionProps,
            finalizedPrice: item.price,
            backgroundId: item.backgroundId,
            premiumBackground: item.premiumBackground,
          };
        }),
      },
    });
  }

  removeQuantity(item: CartItem, numberToRemove: number) {
    const allOtherItems = this.state.cartItems.filter((ci) => {
      return !(
        ci.productId === item.productId &&
        ci.colorId === item.colorId &&
        ci.sizeId === item.sizeId &&
        ci.nftId === item.nftId &&
        ci.title === item.title
      );
    });

    const quantity = item.quantity - numberToRemove;

    const orderItems = [
      ...allOtherItems,
      {
        ...item,
        quantity,
        price: ((item.price ?? 0) / item.quantity) * quantity,
      },
    ];

    const subtotal = orderItems
      .map((x) => x.price ?? 0)
      .reduce((a, b) => a + b);

    this.setState({
      cartItems: orderItems,
      subtotal,
    });

    this.updateOrder({
      variables: {
        sessionId: this.state.sessionId,
        orderItems: orderItems.map((item) => {
          return {
            productId: item.productId,
            quantity: item.quantity,
            colorId: item.colorId,
            sizeId: item.sizeId,
            variationId: item.variationId,
            title: item.title,
            size: item.size ?? "",
            color: item.color ?? "",
            productType: item.productType,
            nftId: item.nftId,
            svg: item.svg,
            positionProps: item.positionProps,
            finalizedPrice: item.price,
            backgroundId: item.backgroundId,
            premiumBackground: item.premiumBackground,
          };
        }),
      },
    });
  }

  async addToCart(
    item: CartItem,
    nftId: number,
    nftName: string,
    nftPrice: number,
    freeItems?: CartItem[],
    noAutoNft?: boolean
  ) {
    let existingItem = this.state.cartItems.find((ci) => {
      if (item.productType === ProductType.Axomo) {
        return (
          ci.productId === item.productId &&
          ci.colorId === item.colorId &&
          ci.sizeId === item.sizeId &&
          ci.nftId === (item.nftId ?? nftId) &&
          ci.title === item.title
        );
      }

      return (
        ci.nftId === item.nftId &&
        ci.productType === item.productType &&
        ci.title === item.title
      );
    });
    // If you are trying to add an nft again stop
    if (existingItem && existingItem.productType === ProductType.Nft) {
      return;
    }

    // If this item is already added to the cart then we want to update quantity
    const quantity = (existingItem?.quantity ?? 0) + item.quantity;

    // Filter out item that we are updating then reinsert it with new quantity if applicable
    let orderItems = uniq([
      ...this.state.cartItems.filter(
        (x) =>
          !(
            x.productId === item.productId &&
            x.colorId === item.colorId &&
            x.sizeId === item.sizeId
          )
      ),
      existingItem
        ? {
            ...existingItem,
            quantity,
            price: (item.price ?? 0) * quantity,
          }
        : {
            ...item,
            price: (item.price ?? 0) * quantity,
            dateAdded: TimeDateIso.now(),
            nftId,
          },
    ]);

    const existingTii = this.state.cartItems.find(
      (ci) =>
        ci.nftId === nftId &&
        ci.title === nftName &&
        ci.productType === ProductType.Nft
    );
    // If we should insert tii add to existing list
    if (
      !existingTii &&
      item.nftId !== nftId &&
      item.title !== nftName &&
      !noAutoNft
    ) {
      orderItems.push({
        quantity: 1,
        title: nftName,
        productType: ProductType.Nft,
        nftId,
        dateAdded: TimeDateIso.now(),
        price: nftPrice,
        backgroundId: item.backgroundId,
        premiumBackground: item.premiumBackground,
      });
    }
    if (freeItems && freeItems?.length > 0) {
      for (const item of freeItems) {
        orderItems.push({
          ...item,
          dateAdded: TimeDateIso.now(),
        });
      }
    }

    const subtotal = orderItems
      .map((x) => x.price ?? 0)
      .reduce((a, b) => a + b);

    await this.setState({
      cartItems: orderItems,
      cartIsOpen: true,
      hasNft: orderItems.some((item) => item.productType === ProductType.Nft),
      subtotal,
    });

    await this.updateOrder({
      variables: {
        sessionId: this.state.sessionId,
        orderItems: orderItems.map((item) => {
          return {
            productId: item.productId,
            quantity: item.quantity,
            colorId: item.colorId,
            sizeId: item.sizeId,
            variationId: item.variationId,
            title: item.title,
            size: item.size ?? "",
            color: item.color ?? "",
            productType: item.productType,
            nftId: item.nftId,
            svg: item.svg,
            positionProps: item.positionProps,
            finalizedPrice: item.price,
            backgroundId: item.backgroundId,
            premiumBackground: item.premiumBackground,
          };
        }),
      },
    });
  }

  removeFromCart(tii: CartItem) {
    const orderItems = uniq([
      ...this.state.cartItems.filter((x) => {
        // If we are removing the nft remove that and everything that matches the nft id
        if (tii.productType === ProductType.Nft) {
          return x.nftId !== tii.nftId;
        }

        // Removing item should just remove that specific item
        return !(
          x.productId === tii.productId &&
          x.colorId === tii.colorId &&
          x.sizeId === tii.sizeId &&
          x.title === tii.title &&
          x.nftId === tii.nftId &&
          x.productType === tii.productType
        );
      }),
    ]);
    const prices = orderItems.map((x) => x.price ?? 0);
    const subtotal = prices.length > 0 ? prices.reduce((a, b) => a + b) : 0;

    this.setState({
      cartItems: orderItems,
      hasNft: orderItems.some((item) => item.productType === ProductType.Nft),
      subtotal,
    });

    this.updateOrder({
      variables: {
        sessionId: this.state.sessionId,
        orderItems: orderItems.map((item) => {
          return {
            productType: item.productType,
            productId: item.productId,
            quantity: item.quantity,
            colorId: item.colorId,
            sizeId: item.sizeId,
            variationId: item.variationId,
            title: item.title,
            size: item.size ?? "",
            color: item.color ?? "",
            nftId: item.nftId,
            svg: item.svg,
            positionProps: item.positionProps,
            finalizedPrice: item.price,
            backgroundId: item.backgroundId,
            premiumBackground: item.premiumBackground,
          };
        }),
      },
    });
  }
}

export const CartContext = React.createContext<CartHolder>(null!);

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider: React.FC<{}> = (props) => {
  const session = useSession();

  const getActiveCart = useQueryBundle(GetActiveCart, {
    variables: {
      sessionId: session,
    },
  });

  const [state, setState] = useState<CartState>({
    cartIsOpen: false,
    cartItems: [],
    sessionId: session,
    isLoadingCart: true,
    subtotal: 0,
    hasNft: false,
  });

  useEffect(() => {
    if (getActiveCart.state === "DONE") {
      if (!getActiveCart.data.getActiveCart) {
        setState({
          ...state,
          isLoadingCart: false,
        });
        return;
      }

      setState({
        ...state,
        cartItems: getActiveCart.data.getActiveCart.orderItems.map((item) => {
          return {
            title: item.title,
            productId: item.productId ?? undefined,
            colorId: item.colorId ?? undefined,
            sizeId: item.sizeId ?? undefined,
            variationId: item.variationId ?? undefined,
            quantity: item.quantity,
            size: item.size ?? undefined,
            color: item.color ?? undefined,
            productType: item.productType,
            nftId: item.nftId ?? undefined,
            dateAdded: item.dateAdded,
            svg: item.svg ?? undefined,
            positionProps: item.positionProps
              ? {
                  logoX: item.positionProps.logoX,
                  logoY: item.positionProps.logoY,
                  logoWidth: item.positionProps.logoWidth,
                  logoHeight: item.positionProps.logoHeight,
                  tiiFontSize: item.positionProps.tiiFontSize,
                  color:
                    (item.positionProps.color as TiiColorChoices) ?? undefined,
                }
              : undefined,
            price: item.price ?? undefined,
            nftName: item.nftName ?? undefined,
            backgroundId: item.backgroundId ?? undefined,
            premiumBackground: item.premiumBackground ?? undefined,
          };
        }),
        subtotal: getActiveCart.data.getActiveCart.subTotal,
        isLoadingCart: false,
        hasNft: getActiveCart.data.getActiveCart.orderItems.some(
          (item) => item.productType === ProductType.Nft
        ),
      });
    }
  }, [getActiveCart.state]);

  const saveState = (partialState: Partial<CartState>) => {
    setState({ ...state, ...partialState });
  };

  const [createOrUpdateOrder] = useMutation(CreateOrUpdateOrder.Document);

  return (
    <CartContext.Provider
      value={new CartHolder(state, saveState, createOrUpdateOrder)}
    >
      {props.children}
    </CartContext.Provider>
  );
};
