import React from "react";
import styles from "./Banner.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/sharp-light-svg-icons";

interface Props {
  title?: string;
  bodyText: string;
  linkUrl?: string;
  linkText?: string;
  onClick?(e): void;
  className?: string;
  onClose?(e): void;
  linkTarget?: string;
  qaStyleBanner?: boolean;
}

const Banner = (props: Props) => {
  const inner = () => (
    <p className={styles.Banner__content}>
      {props.title && (
        <h2
          className={styles.Banner__title}
          dangerouslySetInnerHTML={{ __html: props.title }}
        ></h2>
      )}
      <span
        className={
          props.qaStyleBanner ? styles.Banner__text__qa : styles.Banner__text
        }
      >
        {props.bodyText}
      </span>
      {props.linkUrl && props.linkText && (
        <span
          className={
            props.qaStyleBanner ? styles.Banner__link__qa : styles.Banner__link
          }
          id="banner-link"
        >
          {props.linkText}
          {props.qaStyleBanner && (
            <FontAwesomeIcon
              className={styles.Banner__icon}
              icon={faArrowRight}
            />
          )}
        </span>
      )}
    </p>
  );

  return (
    <div className={[styles.Banner, props.className].filter(Boolean).join(" ")}>
      <div className={styles.Banner__container}>
        {props.onClose && (
          <span onClick={props.onClose} className={styles.Banner__close}>
            ✕
          </span>
        )}
        {props.linkUrl ? (
          <a
            target={props.linkTarget || "_blank"}
            href={props.linkUrl}
            onClick={props.onClick}
          >
            {inner()}
          </a>
        ) : (
          <span>{inner()}</span>
        )}
      </div>
    </div>
  );
};

export default Banner;
