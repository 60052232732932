import { withLDProvider } from "launchdarkly-react-client-sdk";

const LDConfig = {
  clientSideID: process.env.LAUNCHDARKLY_CLIENT_ID!,
  options: {
    bootstrap: "localStorage" as const,
    streaming: true, // Enable streaming updates
  },
  kind: "user",
  key: "anonymous",
  anonymous: true,
};

export const withOptimizedLDProvider = withLDProvider(LDConfig);
