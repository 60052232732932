export interface PlaidErrorDetails {
  error?: {
    errorCode?: string;
    errorType?: string;
    errorMessage?: string;
  };

  metadata?: {
    institutionName?: string;
    institutionId?: string;
    linkSessionId?: string;
    requestId?: string;
    status?: string;
    accountId?: string;
  };
}

export function fixBodyCss() {
  console.log("Fixing body css");
  // mks 03-19-2020 - Looks like Plaid leaves an `overflow: hidden` on the
  // body, so after it closes we manually clean up after it.

  setInterval(() => {
    const body = document.getElementsByTagName("body")[0];
    body.setAttribute("style", "overflow: initial");
  }, 50);
}
