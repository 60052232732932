import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useFlags } from "source/hooks/useFlags";
import styles from "./DevelopmentGatekeeper.module.scss";

const DevelopmentGatekeeper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const flags = useFlags();

  const isDevelopmentOrStaging = (): boolean => {
    if (typeof window === "undefined") return false; // For server-side rendering

    const hostname = window.location.hostname;
    return (
      hostname === "localhost" ||
      hostname.startsWith("development.") ||
      hostname.startsWith("staging.")
    );
  };

  const redirectToMainSite = () => {
    window.location.href = "https://tiicker.com";
  };

  useEffect(() => {
    const checkAuthorization = async () => {
      if (isDevelopmentOrStaging()) {
        const savedEmail = localStorage.getItem("authorizedEmail");
        if (
          savedEmail &&
          flags.stagingDevelopmentAllowedUsers.includes(savedEmail)
        ) {
          setIsAuthorized(true);
          return;
        }

        const { value: email } = await Swal.fire({
          title: "Enter your email",
          input: "email",
          inputPlaceholder: "Enter your email address",
          showCancelButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: styles.DevelopmentGatekeeper__swalButtons__confirm,
          },
          inputValidator: (value) => {
            if (!value) {
              return "You need to enter your email!";
            }
          },
        });

        if (email) {
          if (flags.stagingDevelopmentAllowedUsers.includes(email)) {
            setIsAuthorized(true);
            localStorage.setItem("authorizedEmail", email); // Save for future visits
          } else {
            await Swal.fire({
              icon: "error",
              title: "Access Denied",
              text: "You are not authorized to access this environment. Redirecting to tiicker.com...",
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              timer: 3000, // Show message for 3 seconds before redirecting
            });
            redirectToMainSite();
          }
        } else {
          redirectToMainSite(); // Redirect if no email was provided
        }
      } else {
        setIsAuthorized(true);
      }
    };

    checkAuthorization();
  }, [flags.stagingDevelopmentAllowedUsers]);

  if (!isAuthorized) {
    return null; // or return a loading spinner
  }

  return <>{children}</>;
};

export default DevelopmentGatekeeper;
