import {
  ApolloQueryResult,
  FetchMoreOptions,
  FetchMoreQueryOptions,
  NetworkStatus,
  ObservableQuery,
  QueryResult,
  useMutation,
  useQuery,
  useLazyQuery,
  MutationHookOptions,
  MutationTuple,
  QueryLazyOptions,
  QueryHookOptions,
} from "@apollo/client";
import { v4 } from "uuid";
import { useMemo, useCallback, useState, useContext, useEffect } from "react";
import { GraphqlBundle } from "$gql/core";
import { useRouter } from "next/router";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";
import { UserFragment } from "$gql/fragments/general/User.gen";
import { UserContext } from "./hoc/UserWrapper";
import { UpdateMailingAgreementOnUser } from "$gql/mutations/general/UpdateMailingAgreementOnUser.gen";
import {
  AustralianStates,
  CanadianProvinces,
  MexicanStates,
  StateDropdownOptions,
} from "components/DemographicInformation/dropdown-options";
import * as Sentry from "@sentry/nextjs";
import { useFlags } from "source/hooks/useFlags";

/** Extra query stuff we get from apollo hooks. */
type QueryExtras<TData, TVariables> = Pick<
  ObservableQuery<TData, TVariables>,
  "refetch" | "startPolling" | "stopPolling" | "updateQuery"
> & {
  fetchMore<K extends keyof TVariables>(
    fetchMoreOptions: FetchMoreQueryOptions<TVariables, K> &
      FetchMoreOptions<TData, TVariables>
  ): Promise<ApolloQueryResult<TData>>;
};

export type QueryBaseResult<TData, TVariables> = Omit<
  QueryResult<TData, TVariables>,
  "data"
> & {
  data: TData;
} & QueryExtras<TData, TVariables>;

export type HookQueryResult<TResult, TVars> =
  // Initial loading state. No data to show.
  // Skipped is a key on the loading type to avoid having two types returning QueryExtras
  // (which doesn't have the data key)
  | ({ state: "LOADING"; skipped: boolean } & QueryExtras<TResult, TVars>)
  // Updating, but we have data to show. Usually render this.
  | ({ state: "UPDATING" } & QueryBaseResult<TResult, TVars>)
  // Loaded. We have data to show
  | ({ state: "DONE" } & QueryBaseResult<TResult, TVars>)
  | ({ state: "ERROR" } & QueryBaseResult<TResult, TVars>);

export function useQueryBundle<Result, Vars>(
  query: GraphqlBundle<Result, Vars>,
  options?: QueryHookOptions<Result, Vars>
): HookQueryResult<Result, Vars> {
  const rawResult = useQuery<Result, Vars>(query.Document, {
    ...options,
  });

  const ourResult = useMemo<HookQueryResult<Result, Vars>>((): any => {
    if (rawResult.error || rawResult.networkStatus === NetworkStatus.error) {
      return { state: "ERROR", ...rawResult };
    } else if (!rawResult.data || Object.keys(rawResult.data).length == 0) {
      if (options && options.skip) {
        return { state: "LOADING", skipped: true, ...rawResult };
      }
      return { state: "LOADING", skipped: false, ...rawResult };
    } else if (rawResult.loading) {
      return { state: "UPDATING", ...rawResult };
    } else {
      return { state: "DONE", ...rawResult };
    }
  }, [rawResult, options]);

  if (ourResult.state == "ERROR") {
    const isUnauthorized =
      ourResult.error &&
      ourResult.error.networkError &&
      (ourResult.error.networkError as any).statusCode === 403;
    if (!isUnauthorized) {
      console.error(`Query failed: ${rawResult.error}`);
    }
  }
  return ourResult;
}

export function useLazyQueryBundle<Result, Vars>(
  query: GraphqlBundle<Result, Vars>,
  options?: QueryHookOptions<Result, Vars>
): (
  options?: QueryLazyOptions<Vars> | undefined
) => HookQueryResult<Result, Vars> {
  const rawLazyResult = useLazyQuery<Result, Vars>(query.Document, {
    ...options,
  });

  const [executeQuery, data] = rawLazyResult;

  const ourExecute = useCallback(
    (options?: QueryLazyOptions<Vars> | undefined) => {
      executeQuery(options);

      const ourResult = useMemo<HookQueryResult<Result, Vars>>((): any => {
        if (data.error || data.networkStatus === NetworkStatus.error) {
          return { state: "ERROR", ...rawLazyResult };
        } else if (!data.data || Object.keys(data.data).length == 0) {
          return { state: "LOADING", skipped: false, ...rawLazyResult };
        } else if (data.loading) {
          return { state: "UPDATING", ...rawLazyResult };
        } else {
          return { state: "DONE", ...rawLazyResult };
        }
      }, [rawLazyResult]);

      if (ourResult.state == "ERROR") {
        const isUnauthorized =
          ourResult.error &&
          ourResult.error.networkError &&
          (ourResult.error.networkError as any).statusCode === 403;
        if (!isUnauthorized) {
          throw new Error(`Query failed: ${data.error}`);
        }
      }

      return ourResult;
    },
    [executeQuery, data]
  );

  return ourExecute;
}

export function useMutationBundle<T, TVariables>(
  mutation: GraphqlBundle<T, TVariables>,
  options?: MutationHookOptions<T, TVariables>
): MutationTuple<T, TVariables> {
  return useMutation(mutation.Document, options);

  // This may be too aggressive, but if this works well it'll be easy to provide
  // other options
  // return [useLoadingIndicator(func), result];
}

export type AuthResponse = {
  login(redirectTo?: string): Promise<void>;
  signUp(redirectTo?: string, partner_referral?: string): Promise<void>;
  smsSignUp(redirectTo?: string): Promise<void>;
  logout(): void;
};

export function useAuth(): AuthResponse {
  const router = useRouter();
  const { loginWithRedirect, logout } = useAuth0();
  const flags = useFlags();
  const { smsVerificationOn182 } = flags; // Call useFlags at the top level

  const fallbackRedirect =
    typeof window !== "undefined"
      ? `${window.location.origin}/callback`
      : undefined;

  const prepareForJump = (loginType: LoginType, redirectUri?: string) => {
    const cookie: AuthStateCookie = {
      lastKnownLocation: redirectUri ?? router.asPath,
      loginType,
    };
    Cookies.set(CookieName.AuthState, cookie);
  };

  const signUp = (redirectTo?: string, partner_referral?: string) => {
    prepareForJump("signup", redirectTo);

    const referralCode = Cookies.get("referralCode");
    const promoReferral = Cookies.get("promoReferral");

    return loginWithRedirect({
      redirectUri: fallbackRedirect,
      referred_from: referralCode,
      promo_referral: promoReferral,
      partner_referral,
    });
  };

  const smsSignUp = async (redirectTo?: string) => {
    if (smsVerificationOn182) {
      await router.push("/sms-verification");
    } else {
      await signUp();
    }
  };

  return {
    login: (redirectTo?: string) => {
      prepareForJump("login", redirectTo);
      return loginWithRedirect({
        login: true,
        redirectUri: fallbackRedirect,
      });
    },
    smsSignUp,
    signUp,
    logout: () => {
      prepareForJump("logout");
      return logout({
        returnTo: `${window.location.origin}`,
      });
    },
  };
}

export enum CookieName {
  AuthState = "tii:authState",
  Vuid = "vuid",
  AuthCookie = "tiicker-auth-token",
  StackAdaptTracked = "tiisat",
  ViewedPerks = "tii:viewedPerks",
  SubmittedEmail = "tii:submittedEmail",
  PlaidLinkToken = "plaid-link-token",
}

export type LoginType = "login" | "signup" | "logout";
export type AuthStateCookie = {
  lastKnownLocation: string;
  loginType: LoginType;
};

export type ViewedPerksCookie = {
  perkIds: string[];
};

export type EmailSubmission = {
  email: string;
  perksVotedFor: {
    tickerSymbol: string;
  }[];
};

export type VuidCookie = string;

export function useCookie<T>(cookieName: CookieName): T | undefined {
  const result = useMemo<T | undefined>(() => {
    const cookie = Cookies.get(cookieName);
    try {
      const parsedKnownState = cookie ? JSON.parse(cookie) : undefined;
      return parsedKnownState as T | undefined;
    } catch (e) {
      return cookie as T | undefined;
    }
  }, []);

  return result;
}

export type ClientUser = UserFragment;

export const useFetchUser = () => {
  return useContext(UserContext);
};

export const useAuthenticatedUser = ():
  | { state: "LOADING" }
  | {
      state: "DONE";
      user: ClientUser;
      admin: boolean;
      sales: boolean;
      refresh(): void;
    } => {
  const { user, loading, admin, sales, refresh } = useFetchUser();
  const auth = useAuth();

  useEffect(() => {
    if (!loading && user === null) {
      console.log("AUTH?", loading, user);
      auth.login();
    }
  }, [loading, user]);

  if (loading || !user) {
    return {
      state: "LOADING",
    };
  }

  return {
    state: "DONE",
    user,
    admin,
    sales,
    refresh: refresh,
  };
};

export function useSession(): string {
  // TODO: Some day we should use a provider and context for this and
  // supply the cookie via SSG, avoiding this whole thing.
  if (typeof window === "undefined") {
    return "";
  }

  const newSession = v4();
  const sessionCookie = Cookies.get("sessionCookie");
  if (sessionCookie === undefined) {
    Sentry.setUser({ id: sessionCookie });
    Cookies.set("sessionCookie", newSession);
  }

  const chosenCookie = sessionCookie !== undefined ? sessionCookie : newSession;
  return chosenCookie;
}

export const useCurrentWidth = (timeout: number = 150) => {
  const getWidth = () =>
    typeof window !== "undefined"
      ? window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      : 0;

  // save current window width in the state object
  let [width, setWidth] = useState(getWidth());

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId: NodeJS.Timeout | null = null;
    let isMounted = true;

    const resizeListener = () => {
      if (timeoutId !== null) {
        // prevent execution of previous setTimeout
        clearTimeout(timeoutId);
      }

      const handleResize = () => {
        const newWidth = getWidth();

        if (isMounted && newWidth !== width) {
          setWidth(newWidth);
        }
      };

      // change width from the state object after x milliseconds
      timeoutId = setTimeout(handleResize, timeout);
    };
    // set resize listener
    typeof window !== "undefined" &&
      window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      isMounted = false;
      typeof window !== "undefined" &&
        window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return width;
};

export const useMailingUpdatesAgreement = (user: UserFragment | undefined) => {
  const [updateMailingAgreement] = useMutationBundle(
    UpdateMailingAgreementOnUser
  );
  const [hasAgreed, setHasAgreed] = useState<boolean>(
    !!user?.hasAgreedToMailingUpdatesOn
  );

  const toggleMailingAgreement = useCallback(async () => {
    setHasAgreed(!hasAgreed);

    await updateMailingAgreement({
      variables: {
        data: !hasAgreed,
        userId: user?.id || 0,
      },
    });
  }, [updateMailingAgreement, user?.id, hasAgreed, setHasAgreed]);

  return { toggleMailingAgreement, hasAgreed };
};

export const useCountryRegionDropDown = (
  defaultCountry: string | undefined
) => {
  const [country, setCountry] = useState(
    defaultCountry ? defaultCountry.toLowerCase() : "united states"
  );

  const stateDropDownOptions = useMemo(() => {
    if (country) {
      if (country === "united states") {
        return StateDropdownOptions;
      } else if (country === "canada") {
        return CanadianProvinces;
      } else if (country === "mexico") {
        return MexicanStates;
      } else if (country === "australia") {
        return AustralianStates;
      }
    }
    return StateDropdownOptions;
  }, [country]);

  const stateProvinceLabel = useMemo(() => {
    if (country === "united states") {
      return "State";
    } else if (country === "canada") {
      return "Province";
    } else if (country === "mexico") {
      return "Estado";
    } else if (country === "australia") {
      return "State or Territory";
    }
    return "State";
  }, [country]);

  return { country, setCountry, stateDropDownOptions, stateProvinceLabel };
};
