import Cookies from "js-cookie";
import { uniq } from "lodash";
import React, { useState } from "react";
import { useContext } from "react";
import { CookieName, useCookie, ViewedPerksCookie } from "source/hooks";

interface PerkState {
  viewedPerkIds: string[]
  newLoginIds: number[]
}

export class PerkContextApi {
  readonly state: PerkState;
  private setState: (state: Partial<PerkState>) => void;

  constructor(state: PerkState, setState: (state: Partial<PerkState>) => void) {
    this.state = state;
    this.setState = setState
  }

  addNewLoginId(loginId: number) {
    this.setState({
      newLoginIds: uniq([...this.state.newLoginIds, loginId])
    })
  }

  addViewedPerks(perkIds: string[]) {
    const newPerkIds = uniq([...this.state.viewedPerkIds, ...perkIds])

    const cookie: ViewedPerksCookie = { perkIds: newPerkIds };
    Cookies.set(CookieName.ViewedPerks, cookie);

    this.setState({
      viewedPerkIds: newPerkIds
    })
  }
}

export const PerkContext = React.createContext<PerkContextApi>(null!)

export const usePerkContext = () => {
  return useContext(PerkContext)
}

export const PerkProvider: React.FC<{}> = (props) => {

  // TODO: Get already viewed perks

  const viewedPerksCookie = useCookie<ViewedPerksCookie>(CookieName.ViewedPerks)

  const [state, setState] = useState<PerkState>({
    newLoginIds: [],
    viewedPerkIds: viewedPerksCookie?.perkIds || []
  })

  const saveState = (partialState: Partial<PerkState>) => {
    setState({ ...state, ...partialState })
  }

  return <PerkContext.Provider value={new PerkContextApi(state, saveState)}>
    {props.children}
  </PerkContext.Provider>
}