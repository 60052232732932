import { ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

export function buildErrorLink(): ApolloLink {
  return onError(({ graphQLErrors, networkError, operation }) => {
    if (networkError) {
      console.log("[Network error]:", networkError);
    }

    if (graphQLErrors) {
      /*
        TBD: a good way to test frontend logic that depends on GraphQL Errors.
      
        Our MockProvider is based on graphql-tools' addMockFunctionsToSchema,
        which doesn't offer a way to mock errors:
        https://www.apollographql.com/docs/graphql-tools/mocking

        Another candidate is react-apollo's <MockedProvider>:
        https://www.apollographql.com/docs/react/recipes/testing#mockedprovider

        ..but it doesn't work with react-apollo-hooks:
        https://github.com/trojanowski/react-apollo-hooks/issues/114

        Maybe try a full-stack browser test?

        (todo: if we want to invest further in mocked apollo,
          revisit the above in light of the move from react-apollo-hooks to react-apollo 3)
      */
      graphQLErrors.forEach(({ message, locations, path }) => {
        const msg = `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`;
        console.error(msg);
      });
    }
  }) as any;
}
