import * as React from "react";
import Head from "next/head";
import { useEffect, useState } from "react";
import { CookieName, useCookie } from "source/hooks";
import Cookies from "js-cookie";

export enum AnalyticsEvent {
  Registration = "AW-666459267/-j3dCOrl3NABEIO55b0C",
  PerkRedemption = "AW-666459267/P8rpCN_sztABEIO55b0C",
  PlaidConnectionCreated = "AW-666459267/Vi8ICP_3ztABEIO55b0C",
  TradeButtonClicked = "AW-666459267/ErmuCJSE3dABEIO55b0C",
}

export function recordEvent() {
  const trackableEnv = process.env.TRACK_ANALYTICS === "true";

  if (
    typeof window !== "undefined" &&
    trackableEnv &&
    // @ts-ignore
    typeof window.gtag_report_conversion === "function"
  ) {
    // @ts-ignore
    window.gtag_report_conversion();

    console.log("gtag_report_conversion called");
  }
}

export function recordStackAdaptPerkClaimed(tickerSymbol: string) {
  // @ts-ignore
  const stackAdapt = typeof window !== "undefined" ? window.saq : undefined;

  if (stackAdapt) {
    stackAdapt("ts", "ahfYKrYKqwiqCPcpo9xugw", {
      action: "PerkClaimed",
      tickerSymbol: `{{${tickerSymbol}}}`,
    });
  }
}

type Props = { analyticsEvent?: AnalyticsEvent };

const AnalyticsRecorder: React.FC<Props> = (props) => {
  const trackableEnv = process.env.TRACK_ANALYTICS === "true";

  const loadGTagReportConversionCode =
    props.analyticsEvent &&
    (props.analyticsEvent == AnalyticsEvent.PerkRedemption ||
      props.analyticsEvent == AnalyticsEvent.PlaidConnectionCreated ||
      props.analyticsEvent == AnalyticsEvent.TradeButtonClicked);

  const isStackAdaptTracked = useCookie<string>(CookieName.StackAdaptTracked);
  const [hasTrackedConversion, setHasTrackedConversion] = useState(
    isStackAdaptTracked === "true"
  );

  // @ts-ignore
  const stackAdapt = typeof window !== "undefined" ? window.saq : undefined;

  useEffect(() => {
    if (
      props.analyticsEvent === AnalyticsEvent.Registration &&
      stackAdapt &&
      !hasTrackedConversion &&
      trackableEnv
    ) {
      stackAdapt("ts", "ahfYKrYKqwiqCPcpo9xugw", { action: "create_account" });
      setHasTrackedConversion(true);
      Cookies.set(CookieName.StackAdaptTracked, "true");
    }
  }, [props.analyticsEvent, stackAdapt, isStackAdaptTracked, trackableEnv]);

  if (!trackableEnv) {
    return null;
  } else {
    return (
      <>
        <Head>
          {props?.analyticsEvent === AnalyticsEvent.Registration && (
            <script
              dangerouslySetInnerHTML={{
                __html: `gtag('event', 'conversion', {
                'send_to': '${props?.analyticsEvent}',
                'value': 10.0,
                'currency': 'USD'
            });`,
              }}
            />
          )}

          {loadGTagReportConversionCode && (
            <script
              dangerouslySetInnerHTML={{
                __html: `
              function gtag_report_conversion(url) {
                var callback = function () {
                  if (typeof(url) != 'undefined') {
                    window.location = url;
                  }
                };
                if(gtag) {
                  gtag('event', 'conversion', {
                      'send_to': '${props?.analyticsEvent}',
                      'value': 10.0,
                      'currency': 'USD',
                      'transaction_id': '',
                      'event_callback': callback
                  });
                }
                return false;
              }`,
              }}
            />
          )}
        </Head>
      </>
    );
  }
};
export default AnalyticsRecorder;
