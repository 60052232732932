import React, { useState, useEffect, useCallback } from "react";
import { FlagsContext, FlagsState, defaultFlags } from "./FlagsContext";

const parseJsonSafely = (jsonString: string): any => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return null;
  }
};

const findLaunchDarklyKey = (): string | null => {
  if (typeof window === "undefined") return null;

  for (let i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i);
    if (key) {
      const testValue = window.localStorage.getItem(key);
    }
    if (key && key.startsWith("ld:")) {
      const value = window.localStorage.getItem(key);
      if (value) {
        try {
          const parsedValue = parseJsonSafely(value);
          if (
            parsedValue !== null &&
            typeof parsedValue === "object" &&
            "$schema" in parsedValue
          ) {
            console.log("Found LaunchDarkly key:", key);
            return key;
          }
        } catch (error) {
          console.warn(
            `Error parsing localStorage item for key ${key}:`,
            error
          );
          // Continue to the next item
        }
      }
    }
  }

  console.warn("No valid LaunchDarkly key found in localStorage");
  return null;
};

const parseFlagsFromStorage = (key: string): FlagsState => {
  if (typeof window === "undefined") return defaultFlags;

  try {
    const storedValue = window.localStorage.getItem(key);
    if (storedValue) {
      const parsedFlags = JSON.parse(storedValue);
      return Object.keys(defaultFlags).reduce((acc, flagKey) => {
        const camelCaseKey = flagKey as keyof FlagsState;
        const storedFlagKey = flagKey
          .replace(/([A-Z])/g, "-$1")
          .toLowerCase()
          .replace(/(-?)(\d+)$/, "-$2");

        if (storedFlagKey in parsedFlags) {
          if (camelCaseKey === "stagingDevelopmentAllowedUsers") {
            acc[camelCaseKey] = Array.isArray(parsedFlags[storedFlagKey].value)
              ? parsedFlags[storedFlagKey].value
              : defaultFlags[camelCaseKey];
          } else {
            acc[camelCaseKey] =
              typeof parsedFlags[storedFlagKey].value === "boolean"
                ? parsedFlags[storedFlagKey].value
                : defaultFlags[camelCaseKey];
          }
        } else {
          // @ts-ignore
          acc[camelCaseKey] = defaultFlags[camelCaseKey];
        }

        return acc;
      }, {} as FlagsState);
    }
  } catch (error) {
    console.error("Error parsing flags from storage:", error);
  }

  return defaultFlags;
};

const useLocalStorage = (key: string | null, initialValue: FlagsState) => {
  const [storedValue, setStoredValue] = useState<FlagsState>(() => {
    if (typeof window === "undefined" || !key) return initialValue;
    try {
      return parseFlagsFromStorage(key);
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: FlagsState | ((val: FlagsState) => FlagsState)) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
      } catch (error) {
        console.error(error);
      }
    },
    [storedValue]
  );

  useEffect(() => {
    if (typeof window === "undefined" || !key) return;

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === key) {
        setValue(parseFlagsFromStorage(key));
      }
    };

    window.addEventListener("storage", handleStorageChange);

    // Poll for changes every second (adjust as needed)
    const intervalId = setInterval(() => {
      setValue(parseFlagsFromStorage(key));
    }, 60000);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      clearInterval(intervalId);
    };
  }, [key, setValue]);

  return [storedValue, setValue] as const;
};

export const FlagsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const ldKey = findLaunchDarklyKey();
  const [flags, setFlags] = useLocalStorage(ldKey, defaultFlags);

  return (
    <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
  );
};
