import { ApolloProvider } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { buildGraphqlClient } from "$gql/client";
import React, { useMemo } from "react";
import Cookies from "js-cookie";
import { CookieName, useCookie } from "source/hooks";

interface Props {
  children: React.ReactNode;
}

const AuthenticatedApolloProvider = (props: Props) => {
  // TODO: check if cookie is set and override this
  const { getAccessTokenSilently } = useAuth0();
  const authCookie = useCookie<string>(CookieName.AuthCookie);

  const client = useMemo(
    () =>
      buildGraphqlClient({
        baseURL: process.env.API_URL,
        getAuthToken: async () => {
          if (authCookie) {
            return authCookie;
          }

          try {
            const token = await getAccessTokenSilently();
            return token;
          } catch (e) {
            return undefined;
          }
        },
      }),
    [getAccessTokenSilently]
  );
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};

export default AuthenticatedApolloProvider;
